import clsx from 'clsx';
import { Button } from 'components/shared/Button';
import { Checkbox } from 'components/shared/Checkbox/Checkbox';
import { CrossIcon } from 'components/shared/Icons/CrossIcon';
import { ResetIcon } from 'components/shared/Icons/ResetIcon';
import { RocketIcon } from 'components/shared/Icons/RocketIcon';
import { SearchIcon } from 'components/shared/Icons/SearchIcon';
import { ServicesIcon } from 'components/shared/Icons/ServicesIcon';
import { WalletIcon } from 'components/shared/Icons/WalletIcon';
import { Input } from 'components/shared/Input';
import { Modal } from 'components/shared/Modal';
import { handleFilterServices } from 'containers/CardsContainer/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { marketPlaceStore } from 'stores/marketPlaceStore';
import { userStore } from 'stores/userStore';
import { declOfNum } from 'tools/utils';
import { FilterProps, ServiceMobileFilterProps } from 'types/solutions';
import classes from './MobileFilters.module.scss';
import { useCategoryFilters } from './useCategoryFilters';
import { useMobileFilters } from './useMobileFilters';
import { usePriseFilters } from './usePriceFilters';

export const MobileFilters = ({
  serviceTypesChecks,
  setServiceTypes,
  businessTypesChecks,
  setBusinessTypes,
  maxCost,
  budget,
  setBudget,
  isOpen,
  onClose,
  count,
  serviceTypesOf,
  businessTypesOf,
  setCurrentPage,
  pageSize,
  setProductsToShow,
  topFilters,
  isFavorites,
  sortSelect,
  priceMin,
  setClearBusinessTypes,
  setMoreButtonClicked,
  setPriceMin,
  setIsFirstRender,
}: ServiceMobileFilterProps) => {
  const { handleBlur, handleKeyDown } = useMobileFilters();
  const { t, i18n } = useTranslation();

  const [showServices, setShowServices] = useState(false);
  const [showBusiness, setShowBusiness] = useState(false);

  const sortParams = () => {
    switch (sortSelect?.id) {
      case 1:
        return 'popular';
      case 2:
        return 'cost_lth';
      case 3:
        return 'cost_htl';
    }
  };
  const order = sortParams();
  const handleFilter = (options: FilterProps) => {
    handleFilterServices(
      options,
      pageSize,
      businessTypesChecks,
      serviceTypesChecks,
      setProductsToShow,
      topFilters,
      isFavorites,
      order
    );
  };

  const {
    handleClearPrice,
    minValue,
    handleChangeMinValue,
    handleChangeMinPrice,
    maxValue,
    handleChangeMaxValue,
    handleChangeMaxPrice,
    setMinValue,
    setMaxValue,
  } = usePriseFilters(
    budget,
    maxCost,
    priceMin,
    setPriceMin,
    setBudget,
    setMoreButtonClicked,
    handleFilter,
    setCurrentPage
  );

  const {
    resetSearchService,
    setServiceSearch,
    serviceSearch,
    serviceOptions,
    resetSearchBusiness,
    setBusinessSearch,
    businessSearch,
    businessOptions,
  } = useCategoryFilters(
    setServiceTypes,
    setIsFirstRender,
    setBusinessTypes,
    businessTypesChecks,
    serviceTypesOf,
    businessTypesOf,
    handleFilter,
    isFavorites
  );

  const resetAll = () => {
    setServiceTypes([]);
    setServiceSearch('');

    setClearBusinessTypes(true);
    setBusinessTypes([]);
    setBusinessSearch('');

    setBudget(0);
    setPriceMin(0);
    setMaxValue(maxCost);
    setMinValue(0);
  };

  return (
    <div className={clsx(classes.wrapper)}>
      <div className={classes.filter}>
        <div className={classes.title}>
          <span>
            <ServicesIcon /> {t('Type of solutions')}
            {serviceTypesChecks?.length > 0 && (
              <Button
                onClick={() => resetSearchService()}
                className={classes.clear}
                theme="icon-default"
              >
                {serviceTypesChecks?.length}
                <CrossIcon />
              </Button>
            )}
          </span>

          <Button
            onClick={() => setShowServices(true)}
            className={classes.button}
            theme="text"
          >
            {t('Show all')}
          </Button>
        </div>

        <div className={classes.choices}>
          {serviceOptions.slice(0, 5).map((item: any) => (
            <Checkbox
              key={item.id}
              className={classes.check}
              onChange={() => {
                setCurrentPage(1);
                if (serviceTypesChecks.includes(item.id)) {
                  const arr = serviceTypesChecks.filter(
                    (val: any) => val !== item.id
                  );
                  setServiceTypes(arr);
                  handleFilter({ serviceTypes: arr });
                  return;
                }
                const arr = [...serviceTypesChecks, item.id];
                setServiceTypes(arr);
                handleFilter({ serviceTypes: arr });
              }}
              checked={serviceTypesChecks.includes(item.id)}
              label={item[`name_${i18n.language}`] ?? item.name}
              disabled={marketPlaceStore.isLoadingServicePages}
            />
          ))}
        </div>
        <div className={classes.emptyButton} />
      </div>

      <div className={classes.filter}>
        <div className={classes.title}>
          <span>
            <RocketIcon className={classes.rocket} /> {t('Type of business')}
            {businessTypesChecks?.length > 0 && (
              <Button
                onClick={() => resetSearchBusiness()}
                className={classes.clear}
                theme="icon-default"
              >
                {businessTypesChecks?.length}
                <CrossIcon />
              </Button>
            )}
          </span>

          <Button
            onClick={() => setShowBusiness(true)}
            className={classes.button}
            theme="text"
          >
            {t('Show all')}
          </Button>
        </div>

        <div className={classes.choices}>
          {businessOptions.slice(0, 5).map((item: any) => (
            <Checkbox
              key={item.id}
              className={classes.check}
              onChange={() => {
                if (businessTypesChecks.includes(item.id)) {
                  const arr = businessTypesChecks.filter(
                    (val: any) => val !== item.id
                  );

                  setClearBusinessTypes(true);
                  setBusinessTypes(arr);
                  handleFilter({ businessTypes: arr });
                  return;
                }
                const arr = [...businessTypesChecks, item.id];
                setBusinessTypes(arr);
                handleFilter({ businessTypes: arr });
              }}
              checked={businessTypesChecks.includes(item.id)}
              label={item[`name_${i18n.language}`] ?? item.name}
              disabled={marketPlaceStore.isLoadingServicePages}
            />
          ))}
        </div>
        <div className={classes.emptyButton} />
      </div>

      <div className={classes.filter}>
        <div className={classes.title}>
          <span>
            <WalletIcon /> {t('Price')}
          </span>
          {(priceMin !== 0 || budget !== 0) && (
            <Button
              onClick={() => handleClearPrice()}
              // className={classes.clearPrice}
              className={classes.clear}
              theme="icon-default"
            >
              {t('Clear all')}
              <CrossIcon />
            </Button>
          )}
        </div>

        <div className={classes['choices-budget']}>
          <Input
            onKeyDown={(e) => handleKeyDown(e, handleChangeMinPrice)}
            onBlur={(e) => handleBlur(e, handleChangeMinPrice)}
            currency={userStore.user.currency?.symbol}
            onChange={handleChangeMinValue}
            type={'text'}
            placeholder={t('Min price')}
            value={minValue.toString()}
            disabled={marketPlaceStore.isLoadingServicePages}
            inputForCurrency={true}
          />
          <Input
            onKeyDown={(e) => handleKeyDown(e, handleChangeMaxPrice)}
            onBlur={(e) => handleBlur(e, handleChangeMaxPrice)}
            currency={userStore.user.currency?.symbol}
            onChange={handleChangeMaxValue}
            labelPosition={'left'}
            type={'text'}
            placeholder={t('Max price')}
            value={maxValue?.toString()}
            disabled={marketPlaceStore.isLoadingServicePages}
            inputForCurrency={true}
          />
        </div>
        <div className={classes.emptyButton} />
      </div>

      {showServices && (
        <Modal
          isOpen={showServices}
          onClose={() => setShowServices(false)}
          subModal={true}
          title={t('Types of solutions')}
          className={classes.modalFilter}
          classNameForContent={classes.modalMobileFilters}
        >
          <div className={classes.filter}>
            <div className={classes['search-field']}>
              <SearchIcon />
              <Input
                value={serviceSearch}
                onChange={setServiceSearch}
                placeholder={t('Search')}
                className={classes.search}
              />
            </div>

            <div className={classes.count}>
              <div className={classes.text}>
                {t('Selected')}: {serviceTypesChecks.length}
              </div>
              <Button
                className={classes.clear}
                onClick={() => {
                  resetSearchService();
                }}
                theme="text"
              >
                {t('Clear all')}
              </Button>
            </div>

            <div className={classes.choices}>
              {serviceOptions.map((item: any) => (
                <Checkbox
                  key={item.id}
                  className={classes.check}
                  onChange={() => {
                    setCurrentPage(1);
                    if (serviceTypesChecks.includes(item.id)) {
                      const arr = serviceTypesChecks.filter(
                        (val: any) => val !== item.id
                      );
                      setServiceTypes(arr);
                      handleFilter({ serviceTypes: arr });
                      return;
                    }
                    const arr = [...serviceTypesChecks, item.id];
                    setServiceTypes(arr);
                    handleFilter({ serviceTypes: arr });
                  }}
                  checked={serviceTypesChecks.includes(item.id)}
                  label={item[`name_${i18n.language}`] ?? item.name}
                  disabled={marketPlaceStore.isLoadingServicePages}
                />
              ))}
            </div>
            <div className={classes.emptyButton} />

            <div
              className={clsx(
                classes['bottom-buttons'],
                classes.open,
                classes['bottom-buttons-apply']
              )}
            >
              <Button
                onClick={() => {
                  setServiceTypes([...serviceTypesChecks]);
                  setShowServices(false);
                  setServiceSearch('');
                }}
                className={clsx(classes.button, classes.apply)}
                theme="primary"
              >
                {t('Apply')}
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {showBusiness && (
        <Modal
          isOpen={showBusiness}
          onClose={() => setShowBusiness(false)}
          subModal={true}
          title={t('Type of business')}
          className={classes.modalFilter}
          classNameForContent={classes.modalMobileFilters}
        >
          <div className={classes.filter}>
            <div className={classes['search-field']}>
              <SearchIcon />
              <Input
                value={businessSearch}
                onChange={setBusinessSearch}
                placeholder={t('Search')}
                className={classes.search}
              />
            </div>

            <div className={classes.count}>
              <div className={classes.text}>
                {t('Selected')}: {businessTypesChecks.length}
              </div>
              <Button
                className={classes.clear}
                onClick={() => resetSearchBusiness()}
                theme="text"
              >
                {t('Clear all')}
              </Button>
            </div>

            <div className={classes.choices}>
              {businessOptions.map((item: any) => (
                <Checkbox
                  className={classes.check}
                  onChange={() => {
                    if (businessTypesChecks.includes(item.id)) {
                      const arr = businessTypesChecks.filter(
                        (val: any) => val !== item.id
                      );

                      setClearBusinessTypes(true);
                      setBusinessTypes(arr);
                      handleFilter({ businessTypes: arr });
                      return;
                    }
                    const arr = [...businessTypesChecks, item.id];
                    setBusinessTypes(arr);
                    handleFilter({ businessTypes: arr });
                  }}
                  checked={businessTypesChecks.includes(item.id)}
                  label={item[`name_${i18n.language}`] ?? item.name}
                  disabled={marketPlaceStore.isLoadingServicePages}
                />
              ))}
            </div>
          </div>
          <div className={classes.emptyButton} />

          <div
            className={clsx(
              classes['bottom-buttons'],
              classes.open,
              classes['bottom-buttons-apply']
            )}
          >
            <Button
              onClick={() => {
                setBusinessTypes([...businessTypesChecks]);
                setShowBusiness(false);
                setBusinessSearch('');
              }}
              className={clsx(classes.button, classes.apply)}
              theme="primary"
            >
              {t('Apply')}
            </Button>
          </div>
        </Modal>
      )}
      <div className={classes.emptyButton} />

      <div className={clsx(classes['bottom-buttons'], isOpen && classes.open)}>
        <Button
          className={clsx(classes.button, classes.reset)}
          theme="text"
          onClick={() => resetAll()}
        >
          {t('Reset')} <ResetIcon />
        </Button>

        <Button onClick={onClose} className={classes.button} theme="primary">
          {`${t('Show')} ${count} ${declOfNum(count || 0, [
            t('solution'),
            t('solutions'),
            t('of solutions'),
          ])}`}
        </Button>
      </div>
    </div>
  );
};
