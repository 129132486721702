import _ from 'lodash';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import User from 'services/user';
import { userStore } from 'stores/userStore';
import { getLangName } from 'tools/utils';
import { SystemGoal } from 'types/project';

export const useGoals = (project: any, updateProject: any, tabsId?: string) => {
  const { t, i18n } = useTranslation();
  const editableDivRef = useRef<HTMLInputElement>(null);
  const [projectGoals, setProjectGoals] = useState<SystemGoal[]>(project.goals);
  const [systemProjectGoals, setSystemProjectGoal] = useState<SystemGoal[]>(
    project.project_system_goals
  );
  const [addAnotherGoal, setAddAnotherGoal] = useState<boolean>(false);
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [newGoal, setNewGoal] = useState<SystemGoal | null>(null);
  const [projectId, setProjectId] = useState<number>();
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const userLocale = userStore.user?.language?.lang_code;
  const maxLength = 40;
  const goalName = (goal: any) => getLangName(goal, 'name', i18n);

  useEffect(() => {
    if (newGoal) {
      setProjectId(project.id);
    }
    if (!newGoal) {
      setIsFocused(false);
    }
  }, [newGoal, project.id, projectId]);

  useEffect(() => {
    userStore.loadProjectData(project?.id);
  }, []);

  useEffect(() => {
    updateProject().then((project: any) => {
      setSystemProjectGoal(project?.data?.project_system_goals);
      setProjectGoals(project?.data?.goals);
    });
  }, [userLocale]);

  const handleAddAnotherGoal = () => {
    handleGoal(project.id, newGoal);
    setNewGoal(null);
    setAddAnotherGoal(false);
    setIsFocused(false);
    setProjectId(undefined);
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      handleAddAnotherGoal();
    }
  };

  const handleNonInputClick = (event: any) => {
    if (event.target.closest(`#${tabsId}`)) return;
    if (
      !event.target.closest('input') &&
      !event.target.closest('svg') &&
      projectId &&
      isFocused
    ) {
      event.preventDefault();
      handleAddAnotherGoal();
    } else if (
      !event.target.closest('input') &&
      !event.target.closest('svg') &&
      !isFocused &&
      !goalName(newGoal)
    ) {
      event.preventDefault();
      setNewGoal(null);
      setAddAnotherGoal(false);
      setProjectId(undefined);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleNonInputClick);
    return () => {
      document.removeEventListener('click', handleNonInputClick);
    };
  }, [newGoal, projectId, isFocused, handleNonInputClick]);

  const checkGoals = (goalsArray: SystemGoal[], goal: SystemGoal) =>
    _.findIndex(goalsArray, (o) => _.isMatch(o, goal)) > -1;

  const handleGoal = async (projectId: number, goal: any, removed = false) => {
    setIsClicked(true);
    const finalGoal = goal?.goal ? goal.goal : goal;
    try {
      if (removed) {
        User.deleteGoal(projectId, finalGoal?.id)
          .then(async (data) => {
            setSystemProjectGoal(data.project_system_goals);
            setProjectGoals(data.goals);
          })
          .catch(() => {
            updateProject();
          });
      }
      if (!removed && !checkGoals(projectGoals, finalGoal)) {
        User.updateProject(projectId, finalGoal?.name).then((data) => {
          setProjectGoals(data?.goals);
          setSystemProjectGoal(data?.project_system_goals);
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      updateProject();
    }
    setTimeout(() => setIsClicked(false), 500);
  };

  useEffect(() => {
    if (addAnotherGoal && editableDivRef.current) {
      editableDivRef.current.focus();
    }
  }, [addAnotherGoal]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= maxLength) {
      setNewGoal({ name: value });
      e.target.style.width = `${e.target.scrollWidth}px`;
    }
  };

  return {
    setNewGoal,
    setAddAnotherGoal,
    setIsFocused,
    setProjectId,
    projectGoals,
    handleGoal,
    setIsClicked,
    goalName,
    addAnotherGoal,
    maxLength,
    newGoal,
    editableDivRef,
    handleKeyDown,
    handleChange,
    t,
    isClicked,
    systemProjectGoals,
  };
};
