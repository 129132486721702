import { queryParamsHandler } from 'containers/CardsContainer/utils';
import { useFavorites } from 'hooks';
import { useHotDeals } from 'hooks/useHotDeals';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { marketPlaceStore } from 'stores/marketPlaceStore';

export const useServiceInfo = (data: any) => {
  const [isFavorite, setIsFavorite] = useState(data?.is_favorite);

  const { t } = useTranslation();
  const { getHotDealText } = useHotDeals(t);
  const [hotDealDiscountText, setHotDealDiscountText] = useState('');

  useEffect(() => {
    const params = queryParamsHandler({ isHot: true });
    marketPlaceStore.loadHotDeals(params);
  }, []);

  const navigate = useNavigate();

  const changeFavorites = useFavorites(
    data,
    isFavorite,
    setIsFavorite,
    navigate
  );

  useEffect(() => {
    setIsFavorite(data?.is_favorite);
  }, [data]);

  const deals = data?.discounts?.find((item: any) => item.type === 'HOT_DEAL');

  useEffect(() => {
    setHotDealDiscountText(getHotDealText(deals, data));
  }, [data, deals]);

  const checkDiscount =
    data?.has_active_discounts &&
    Number(data.cost) !== Number(data.cost_with_discounts)
      ? Number(data.cost_with_discounts)
      : Number(data.cost);

  return {
    t,
    deals,
    isFavorite,
    changeFavorites,
    checkDiscount,
    hotDealDiscountText,
  };
};
