import { yupResolver } from '@hookform/resolvers/yup';
import { numbersCheckReg, symbolsCheckReg } from 'constants/matches';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Request } from 'tools/request';
import { ShowNotification } from 'tools/showNotification';
import { FormInputsNewPassword } from 'types/auth';
import { newPasswordSchema } from '../Account/validationSchemas';

export const useNewPassword = () => {
  const { t, i18n } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    setError,
  } = useForm<FormInputsNewPassword>({
    resolver: yupResolver(newPasswordSchema(t('Passwords don’t match'))),
    mode: 'onChange',
  });

  const [numbersCheck, setNumbersCheck] = useState(false);
  const [symbolsCheck, setSymbolsCheck] = useState(false);
  const [showChecks, setShowCheks] = useState(false);
  const [password, setPassword] = useState<string>('');
  const [passwordRep, setPasswordRep] = useState<string>('');
  const [token, setToken] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [step, setStep] = useState(0);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [showPassword, setShowPassword] = useState<any>({
    newPass: false,
    newPassConfirmation: false,
  });

  useEffect(() => {
    const newToken = searchParams.get('token');
    if (newToken) {
      setToken(newToken);
      tokenCheck(newToken);
    }
  }, [searchParams]);

  useEffect(() => {
    if (numbersCheckReg.test(password)) {
      setNumbersCheck(true);
    } else {
      setNumbersCheck(false);
    }
    if (symbolsCheckReg.test(password)) {
      setSymbolsCheck(true);
    } else {
      setSymbolsCheck(false);
    }
  }, [password, passwordRep]);

  const tokenCheck = useCallback(async (t: string) => {
    const formdata = new FormData();

    formdata.append('token', t);

    const result = await Request({
      type: 'POST',
      url: `${process.env.REACT_APP_API}/api/v1/auth/validate-token/`,
      data: formdata,
    });
  }, []);

  const submit = useCallback(
    async (e: any) => {
      const formdata = new FormData();

      formdata.append('token', token);
      formdata.append('new_password', password);
      formdata.append('new_password_repeat', password);

      setDisableSubmit(true);
      try {
        await Request({
          type: 'POST',
          url: `${process.env.REACT_APP_API}/api/v1/auth/new-password/`,
          data: formdata,
        });
        setDisableSubmit(false);
        setStep(1);
      } catch (e: any) {
        if (e.response.status === 400 && e.response.data?.non_field_errors) {
          setError('newPass', {
            type: 'server',
            message: e.response.data?.non_field_errors[0],
          });
        }
        if (e.response.status === 400 && !e.response.data?.is_valid) {
          ShowNotification({
            type: 'error',
            children: t(
              'Your session token has expired. Please request a new restoration link to continue.'
            ),
          });
        }
      }
    },
    [password, setError, t, token]
  );

  const handleShowPassword = (type: string) => {
    setShowPassword({ ...showPassword, [type]: !showPassword[type] });
  };

  const changeLanguage = (langObj: any) => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (newSearchParams.has('lng')) {
      newSearchParams.delete('lng');
      setSearchParams(newSearchParams);
    }
    i18n.changeLanguage(langObj.lang_code);
    localStorage.setItem('kitGlobalLng', langObj.lang_code);
  };

  const passwordChecks = [
    {
      condition: password.length >= 8,
      text: t('At least 8 characters long'),
    },
    {
      condition: numbersCheck,
      text: t('Contains numbers'),
    },
    {
      condition: symbolsCheck,
      text: t('Symbols ~!@#$%^&*()_+<>?￥¿¡·॰।'),
    },
  ];

  return {
    showPassword,
    password,
    i18n,
    changeLanguage,
    t,
    handleSubmit,
    submit,
    errors,
    control,
    showChecks,
    step,
    passwordChecks,
    setShowCheks,
    setPassword,
    passwordRep,
    handleShowPassword,
    disableSubmit,
    isValid,
    setPasswordRep,
  };
};
