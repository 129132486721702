import { Grid, GridItem } from 'components/common/Grid';
import { Button } from 'components/shared/Button';
import { MultiselectComponent } from 'components/shared/MultiselectComponent';
import { Notice } from 'components/shared/Notice';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RUSSIAN_LOCALE } from 'utils';
import classes from './LocalPreferences.module.scss';
import {
  FormValues,
  LocalPreferencesFormProps,
} from './LocalPreferences.props';

export const LocalPreferencesForm = ({
  onClose,
  onSave,
  disabled,
  setIsDisabled,
  onlySaveButton,
  noticeText,
  countries,
  currencies,
  languages,
  currentLanguage,
  currentCountry,
  currentCurrency,
}: LocalPreferencesFormProps) => {
  const { t } = useTranslation();
  const { handleSubmit, control } = useForm<FormValues>();
  const { isMediaTablet } = useWindowWidth();

  const [selectedCountry, setSelectedCountry] = useState<any>(currentCountry);
  const [selectedCurrency, setSelectedCurrency] =
    useState<any>(currentCurrency);
  const [selectedLanguage, setSelectedLanguage] =
    useState<any>(currentLanguage);

  const handleSave = () => {
    const requestData = {
      language: selectedLanguage.lang_code,
      country: { id: selectedCountry?.id || currentCountry?.id },
      currency: { id: selectedCurrency?.id || currentCurrency?.id },
    };
    onSave(requestData);
  };

  useEffect(() => {
    if (setIsDisabled) {
      if (
        (selectedCountry || currentCountry) &&
        selectedCurrency &&
        selectedLanguage
      ) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }
  }, [selectedCountry, selectedCurrency, selectedLanguage]);

  return (
    <form
      action=""
      className={classes.form}
      onSubmit={handleSubmit(handleSave)}
    >
      <div className={classes.inner}>
        {!isMediaTablet && !onlySaveButton && (
          <div className={classes.title}>{t('Local preferences')}</div>
        )}

        <div className={classes['notice-container']}>
          <Notice
            type="warning"
            noticeClass={classes.notice}
            noticeIconClass={classes['notice-icon']}
            childrenClass={classes['notice-text']}
          >
            {noticeText}
          </Notice>
        </div>

        <Grid direction="row" className={classes.box}>
          <GridItem col={{ default: 12 }} className={classes.item}>
            <div className={classes['item-label']}>{t('Currency')}</div>
            <Controller
              render={({ field }) => (
                <MultiselectComponent
                  data={
                    RUSSIAN_LOCALE
                      ? currencies
                      : currencies.filter((cur: any) => cur.char_code !== 'RUB')
                  }
                  defaultSelected={currentCurrency?.id}
                  placeholder={t('Choose Currency')}
                  setMultiselect={setSelectedCurrency}
                  search={false}
                  isCurrency={true}
                />
              )}
              name="currency"
              control={control}
            />
          </GridItem>

          <GridItem col={{ default: 12 }} className={classes.item}>
            <div className={classes['item-label']}>{t('Country')}</div>
            <Controller
              render={({ field }) => (
                <MultiselectComponent
                  data={countries}
                  defaultSelected={currentCountry?.id}
                  placeholder="country"
                  setMultiselect={setSelectedCountry}
                  search={true}
                />
              )}
              name="country"
              control={control}
            />
          </GridItem>

          <GridItem col={{ default: 12 }} className={classes.item}>
            <div className={classes['item-label']}>{t('Language')}</div>
            <Controller
              render={() => (
                <MultiselectComponent
                  data={languages}
                  defaultSelected={currentLanguage?.id}
                  placeholder="language"
                  setMultiselect={setSelectedLanguage}
                  search={false}
                />
              )}
              name="language"
              control={control}
            />
          </GridItem>
        </Grid>

        <div className={classes.bottom}>
          {!onlySaveButton && !isMediaTablet && (
            <Button
              theme="light"
              type="button"
              size="middle"
              className={classes.btn}
              onClick={onClose}
            >
              {t('Back')}
            </Button>
          )}

          <Button
            theme="primary"
            type="submit"
            size="middle"
            disabled={disabled}
            className={classes.btn}
          >
            {t('Save')}
          </Button>
        </div>
      </div>
    </form>
  );
};
