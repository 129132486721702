import { useTranslation } from 'react-i18next';
import clsx from 'classnames';
import classes from './Filters.module.scss';
import { FiltersProps, FilterValues } from './Filters.props';

export const Filters = ({
  all,
  paid,
  topay,
  cancelled,
  filter,
  setFilter,
}: FiltersProps) => {
  const { t } = useTranslation();

  const filterData = [
    { key: 'all', label: t('All'), count: all },
    { key: 'paid', label: t('Paid'), count: paid },
    { key: 'topay', label: t('To pay'), count: topay },
    { key: 'cancelled', label: t('Cancelled'), count: cancelled },
  ];

  return (
    <div className={classes.wrapper}>
      {filterData.map(({ key, label, count }) => (
        <div
          key={key}
          role="button"
          tabIndex={-1}
          onClick={() => {
            if (count !== 0) {
              setFilter(key as FilterValues);
            }
          }}
          className={clsx(
            classes.filter,
            filter === key && classes.active,
            count === 0 && classes.disabled
          )}
        >
          {label}
          <div
            className={clsx(classes.count, count > 9 && classes.countMedium)}
          >
            {count}
          </div>
        </div>
      ))}
    </div>
  );
};
