import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FilterProps } from 'types/solutions';

export const usePriceFilter = (
  budget: number | null,
  maxCost: number,
  priceMin: number,
  setPriceMin: Dispatch<SetStateAction<number>>,
  setBudget: (val: any) => void,
  setMoreButtonClicked: Dispatch<SetStateAction<boolean>>,
  handleFilter: (options: FilterProps) => void,
  setCurrentPage: (val: number) => void
) => {
  // Filter by cost
  const [maxValue, setMaxValue] = useState(0);
  const [minValue, setMinValue] = useState(0);

  useEffect(() => {
    if (budget) {
      // This was done with "-1" so that price_max = 0 is passed in the request
      budget === -1 ? setMaxValue(0) : setMaxValue(budget);
    } else if (budget === 0 || maxCost) {
      setMaxValue(maxCost);
    } else {
      if (budget) {
        // This is done with "-1" to pass price_max = 0 in the request
        budget === -1 ? setMaxValue(0) : setMaxValue(budget);
      }
    }
  }, [maxCost]);

  useEffect(() => {
    setMinValue(priceMin || 0);
  }, [priceMin]);

  const handleChangeMinValue = (e: any) => {
    const value = e.replace(/\s+/g, ''); // Removing spaces as the input may have space-separated values like "1 000" instead of "1000"

    if (/^\d*$/.test(value)) {
      const numValue = Number(value);

      if (numValue <= maxCost) {
        setMinValue(numValue);

        if (budget === 0) setMaxValue(maxCost);
      }
    }
  };

  const handleChangeMaxValue = (e: any) => {
    const value = e.replace(/\s+/g, ''); // Removing spaces as the input may have space-separated values like "1 000" instead of "1000"

    if (/^\d*$/.test(value)) {
      const numValue = Number(value);

      setMaxValue(numValue);
    }
  };

  const handleChangeMinPrice = (e: any) => {
    if (e.includes('e') || e.includes('+')) {
      setMaxValue(maxCost);
      return;
    }

    const value = e.replace(/\s+/g, ''); // Removing spaces as the input may have space-separated values like "1 000" instead of "1000"

    // Check for numeric input only
    if (/^\d*$/.test(value)) {
      const numValue = Number(value);
      const limit = budget !== null && budget > 0 ? budget : maxCost;

      if (numValue === priceMin) return;

      if (numValue <= limit) {
        setMoreButtonClicked(false);
        setPriceMin(numValue);

        if (budget === 0) setBudget(maxCost);

        handleFilter({ price_max: budget, price_min: priceMin });
      } else {
        setMinValue(priceMin);
      }
    }
  };

  const handleChangeMaxPrice = (e: any) => {
    if (e.includes('e') || e.includes('+')) {
      setMaxValue(maxCost);
      return;
    }

    const value = e.replace(/\s+/g, ''); // Removing spaces as the input may have space-separated values like "1 000" instead of "1000"

    // Check for numeric input only
    if (/^\d*$/.test(value)) {
      const numValue = Number(value);

      if (
        (budget === 0 && numValue === maxCost) ||
        (budget && budget > maxCost && numValue !== 0 && numValue === maxCost)
      )
        return;

      if (numValue <= maxCost && numValue >= priceMin) {
        setMoreButtonClicked(false);
        // This is done with "-1" to pass price_max = 0 in the request
        numValue === 0 ? setBudget(-1) : setBudget(numValue);

        handleFilter({ price_max: budget, price_min: priceMin });
      } else if (numValue > maxCost) {
        setMoreButtonClicked(false);
        setBudget(maxCost);
        setMaxValue(maxCost);
        handleFilter({ price_max: maxCost, price_min: priceMin });
      } else {
        if (budget) setMaxValue(budget);
      }
    }
  };

  const handleClearPrice = () => {
    setCurrentPage(1);
    setMoreButtonClicked(false);
    setPriceMin(0);
    setBudget(0);
    setMaxValue(maxCost);
  };

  return {
    handleClearPrice,
    minValue,
    handleChangeMinValue,
    handleChangeMinPrice,
    maxValue,
    handleChangeMaxValue,
    handleChangeMaxPrice,
  };
};
