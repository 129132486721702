import { Label } from './Label';
import { Icon } from 'components/shared/Icon/Icon';
import style from './Input.module.scss';
import clsx from 'clsx';
import { formatNumberWithSpace } from './formatNumberWithSpace';
import { CrossIcon } from 'components/shared/Icons/CrossIcon';
import { TooltipContainer } from 'components/shared/Tooltip/TooltipContainer';
import { QuestionIcon } from 'components/shared/Icons/QuestionIcon';
import { InputProps } from './Input.props';

export const Input = ({
  label,
  labelPosition,
  type = 'text',
  placeholder,
  disabled = false,
  readOnly = false,
  error,
  value,
  icon,
  iconJSX,
  iconFill,
  iconHeight,
  iconWidth,
  className,
  onChange,
  onBlur,
  onFocus,
  maxLength,
  onKeyDown,
  currency,
  inputForCurrency = false,
  inputLabel,
  clearIcon,
  helperText,
  onPaste,
}: InputProps) => {
  const classNames = clsx(
    style.input,
    (icon || iconJSX) && style.inputicon,
    currency && style.inputCurrency,
    error && style.inputError,
    inputLabel && style.inputLabel,
    value && value.length === 0 && inputLabel && style.inputLabelEmptyInput,
    className
  );

  const formattedValue = inputForCurrency
    ? formatNumberWithSpace(value)
    : value;

  return (
    <div className={clsx(style.inputWrap, className)}>
      {label && <Label position={labelPosition}>{label}</Label>}

      <div className={style.inputInner}>
        {icon && !iconJSX && (
          <div className={style.iconWrap}>
            <Icon
              name={icon}
              width={iconWidth}
              height={iconHeight}
              iconFill={iconFill}
            />
          </div>
        )}

        {!icon && iconJSX && <div className={style.iconWrap}>{iconJSX}</div>}

        {currency && <div className={style.currencyWrap}>{currency}</div>}

        <input
          type={type}
          value={formattedValue || ''}
          className={classNames}
          placeholder={inputLabel ? '' : placeholder}
          disabled={disabled}
          readOnly={readOnly}
          onChange={(e) => onChange && onChange(e.target.value)}
          onFocus={onFocus}
          onBlur={onBlur}
          maxLength={maxLength || undefined}
          onKeyDown={onKeyDown}
          onPaste={onPaste || undefined}
        />

        {inputLabel && (
          <div
            className={clsx(
              style.inputLabelPlaceholder,
              (icon || iconJSX) && style.inputIconPlaceholder,
              value.length > 0 && style.inputLabelPlaceholderActive,
              style.inputLabelPlaceholderDefault
            )}
          >
            {placeholder}
          </div>
        )}

        {clearIcon && value.length > 0 && (
          <CrossIcon
            className={style.clearIcon}
            onClick={() => onChange && onChange('')}
            width={'15px'}
            height={'15px'}
          />
        )}

        {helperText && (
          <TooltipContainer
            text={helperText}
            customClasses={'kit-ui-block'}
            position={'top'}
            className={style.error}
          >
            <QuestionIcon fill="#F5222D" width="18px" height="18px" />
          </TooltipContainer>
        )}
      </div>
    </div>
  );
};
