import clsx from 'clsx';
import { Button } from 'components/shared/Button';
import { Filters } from 'components/common/Filters';
import UiIcon from 'components/shared/Icon';
import { FilterIcon } from 'components/shared/Icons/FilterIcon';
import { MobileFilters } from 'components/common/MobileFilters';
import { Modal } from 'components/shared/Modal';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import { Type } from 'components/shared/Type';
import { LABELS } from 'constants/onboarding';
import { RecommendedService } from 'containers/RecommendedService';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { marketPlaceStore } from 'stores/marketPlaceStore';
import classes from './CardsContainer.module.scss';
import { HotDealsCounter } from './HotDeals/HotDealsCounter';
import './IntroTooltip.css';
import { ProductList } from './ProductList';
import { useCardsContainer } from './useCardContainer';
import { useDefineStepsOnboarding } from './useDefineStepsOnboarding';

export const CardsContainer = observer(({ title }: { title: string }) => {
  const {
    businessTypes,
    setBusinessTypes,
    isFavoritesPage,
    budget,
    priceMin,
    setPriceMin,
    t,
    pageName,
    isMobile,
    enableHints,
    handleExit,
    serviceTypes,
    setServiceTypes,
    serviceTypesOfMarketPlace,
    businessTypesOfMarketplace,
    setBudget,
    sortSelect,
    topFilters,
    pageSize,
    setProductsToShow,
    setCurrentPage,
    setIsFirstRender,
    setClearBusinessTypes,
    clearBusinessTypes,
    setIsHot,
    setIsNew,
    setIsFree,
    isServicesLength,
    isFirstRender,
    currentPage,
    productsToShow,
    setIsTopUp,
    setIsSale,
    recommendedServicesIds,
    chunkSize,
    setSortSelect,
    setPageSize,
  } = useCardsContainer(title);
  const { steps } = useDefineStepsOnboarding(isFavoritesPage);
  const { isMediaTablet } = useWindowWidth();
  const { GOT_IT, NEXT, BACK } = LABELS(t);

  const isLoadingSolutions = marketPlaceStore.isLoadingServicePages;
  const isLoadingService = marketPlaceStore.isLoadingServices;
  const { count, maxCost, allCount, favoriteCount } = marketPlaceStore || {};

  const [showFilters, setShowFilters] = useState(false);
  const [openSelect, setOpenSelect] = useState(false);
  const [moreButtonClicked, setMoreButtonClicked] = useState(false);

  return (
    <>
      <TitleAndDescription pageName={pageName} />

      <Steps
        enabled={isMobile && (enableHints as boolean)}
        steps={steps}
        options={{
          tooltipClass: 'customTooltip',
          highlightClass: 'introjs-helperLayer',
          doneLabel: GOT_IT,
          nextLabel: NEXT,
          prevLabel: BACK,
        }}
        initialStep={0}
        onComplete={() => {
          handleExit();
        }}
        onExit={handleExit}
      />

      <div className={clsx(classes.wrapper)}>
        {isFavoritesPage && !favoriteCount ? (
          <div className={classes['zero-favorites']}>
            <div className={classes['zero-favorites__inner']}>
              <div className={classes['zero-favorites__title']}>
                <Type
                  tag="h2"
                  size="lg"
                  className={classes['zero-favorites__text']}
                >
                  {t('No favorite solutions yet?')}
                </Type>
              </div>

              <div className={classes['zero-favorites__subtitle']}>
                <Type
                  tag="h3"
                  size="simple"
                  className={classes['zero-favorites__text']}
                >
                  {t('Try finding something interesting in our catalog')}
                </Type>
              </div>

              <div className={classes['zero-favorites__button']}>
                <Button size="middle" theme="primary">
                  <NavLink
                    to="/solutions"
                    className={classes['zero-favorites__button']}
                  >
                    {t('Back to Solutions')}
                  </NavLink>
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className={classes.head}>
              <div
                className={classes.title}
                key={'Some_kind_of_key_so_that_it_doesn’t_break_Google'}
              >
                {t(`${title}`)}
                <span>{allCount}</span>
              </div>

              {isMediaTablet && (
                <div className={classes.filters}>
                  <Button
                    onClick={() => setOpenSelect(true)}
                    theme="icon-default"
                  >
                    <UiIcon
                      name="FilterMobileTableIcon"
                      additionalClassName={classes.mobileSortIcon}
                    />
                  </Button>

                  <Button
                    onClick={() => setShowFilters(true)}
                    theme="icon-default"
                  >
                    <FilterIcon />
                  </Button>
                </div>
              )}
            </div>

            {isMediaTablet && (
              <Modal
                isOpen={showFilters}
                onClose={() => setShowFilters(false)}
                title={t('Filters')}
                filterMobile
                classNameForContent={classes.modalMobileFilters}
              >
                <MobileFilters
                  count={count}
                  serviceTypesChecks={serviceTypes}
                  setServiceTypes={setServiceTypes}
                  serviceTypesOf={serviceTypesOfMarketPlace}
                  businessTypesOf={businessTypesOfMarketplace}
                  businessTypesChecks={businessTypes}
                  setBusinessTypes={setBusinessTypes}
                  budget={budget}
                  priceMin={priceMin}
                  setPriceMin={setPriceMin}
                  maxCost={maxCost}
                  setBudget={setBudget}
                  isOpen={showFilters}
                  onClose={() => setShowFilters(false)}
                  setCurrentPage={setCurrentPage}
                  setProductsToShow={setProductsToShow}
                  pageSize={pageSize}
                  topFilters={topFilters}
                  isFavorites={isFavoritesPage}
                  sortSelect={sortSelect}
                  setMoreButtonClicked={setMoreButtonClicked}
                  clearBusinessTypes={clearBusinessTypes}
                  setClearBusinessTypes={setClearBusinessTypes}
                  setIsFirstRender={setIsFirstRender}
                />
              </Modal>
            )}

            <div className={classes.content}>
              <div className={classes.left}>
                {!isMediaTablet && (
                  <Filters
                    count={count}
                    pageSize={pageSize}
                    setProductsToShow={setProductsToShow}
                    topFilters={topFilters}
                    setIsFirstRender={setIsFirstRender}
                    isLoading={isLoadingService}
                    serviceTypesChecks={serviceTypes}
                    setServiceTypes={setServiceTypes}
                    businessTypesChecks={businessTypes}
                    setBusinessTypes={setBusinessTypes}
                    serviceTypesOf={serviceTypesOfMarketPlace}
                    businessTypesOf={businessTypesOfMarketplace}
                    budget={budget}
                    priceMin={priceMin}
                    setPriceMin={setPriceMin}
                    maxCost={maxCost}
                    setBudget={setBudget}
                    setCurrentPage={setCurrentPage}
                    isFavorites={isFavoritesPage}
                    setMoreButtonClicked={setMoreButtonClicked}
                    setClearBusinessTypes={setClearBusinessTypes}
                  />
                )}
              </div>

              <div className={classes.right}>
                <ProductList
                  isFirstRender={isFirstRender}
                  isLoading={isLoadingSolutions}
                  setIsFree={setIsFree}
                  setIsNew={setIsNew}
                  setIsHot={setIsHot}
                  setIsSale={setIsSale}
                  setIsTopUp={setIsTopUp}
                  data={productsToShow}
                  hotDeals={marketPlaceStore.dataTimeHotDeals}
                  nameSource={title}
                  defaultCurrentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  setPageSize={setPageSize}
                  defaultServiceTypes={serviceTypes}
                  defaultBusinessTypes={businessTypes}
                  topFilters={topFilters}
                  isFavorites={isFavoritesPage}
                  defaultBudget={budget}
                  defaultPriceMin={priceMin}
                  enableHints={enableHints as boolean}
                  sortSelect={sortSelect}
                  setSortSelect={setSortSelect}
                  openSelect={openSelect}
                  setOpenSelect={setOpenSelect}
                  defaultChunkSize={chunkSize}
                  setMoreButtonClicked={setMoreButtonClicked}
                  moreButtonClicked={moreButtonClicked}
                  setPriceMin={setPriceMin}
                  clearBusinessTypes={clearBusinessTypes}
                />
              </div>
            </div>
          </div>
        )}

        {window.location.pathname === '/favorites' && (
          <RecommendedService
            // ids={recommendedServicesIds}
            nameSource="Favorites"
          />
        )}

        <HotDealsCounter />
      </div>
    </>
  );
});
