import { DealStageType } from 'constants/dealStatuses';
import { DEAL_STAGES } from 'constants/enums';
import 'containers/CardsContainer/IntroTooltip.css';
import { useUserStore } from 'contexts/UserContext';
import 'intro.js/introjs.css';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isOnboardingActive, setOnboardingOptions } from 'tools/onboarding';

export const useProjects = () => {
  const { t } = useTranslation();
  const userStore = useUserStore();
  const userLocale = userStore.user?.language?.lang_code;
  let projects = userStore.projects;

  const enableHints = isOnboardingActive(userStore?.pagesToOnboard?.projects);

  const { PAUSED, COMPLETED, CANCELLED, ACTIVE, ALL } = DEAL_STAGES;

  // filters
  const nonActiveStage = [PAUSED, COMPLETED, CANCELLED];
  const [projectsToPaginate, setProjectsToPaginate] = useState<any>(projects);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState<DealStageType>(ALL as DealStageType);
  const chunkSize = 3;
  const [chunks, setChunks] = useState<any>([]);

  const allCount = projects.length;
  const activeCount = projects.filter(
    (proj: any) => !nonActiveStage.includes(proj.deal_stage?.type.toLowerCase())
  ).length;
  const prodCount = projects.filter(
    (proj: any) => proj.deal_stage?.type.toLowerCase() === COMPLETED
  ).length;
  const pausedCount = projects.filter(
    (proj: any) => proj.deal_stage?.type.toLowerCase() === PAUSED
  ).length;
  const cancelledCount = projects.filter(
    (proj: any) => proj.deal_stage?.type.toLowerCase() === CANCELLED
  ).length;

  const filteredProjects = (projects: any) => {
    switch (filter) {
      case ALL:
        return projects;
      case COMPLETED:
        return projects.filter(
          (proj: any) => proj.deal_stage?.type.toLowerCase() === COMPLETED
        );
      case PAUSED:
        return projects.filter(
          (proj: any) => proj.deal_stage?.type.toLowerCase() === PAUSED
        );
      case CANCELLED:
        return projects.filter(
          (proj: any) => proj.deal_stage?.type.toLowerCase() === CANCELLED
        );
      case ACTIVE:
        return projects.filter(
          (proj: any) =>
            !nonActiveStage.includes(proj.deal_stage?.type.toLowerCase())
        );
      default:
        return projects;
    }
  };

  projects = filteredProjects(projects);

  useEffect(() => {
    setProjectsToPaginate(filteredProjects(projects));
  }, [currentPage, filter, userLocale, userStore.projects]);

  useEffect(() => {
    if (JSON.stringify(projectsToPaginate) !== JSON.stringify(chunks.flat())) {
      const chunkedProjects = [];
      for (let i = 0; i < projectsToPaginate.length; i += chunkSize) {
        chunkedProjects.push(
          projectsToPaginate.length > 2
            ? projectsToPaginate.slice(i, i + chunkSize)
            : projectsToPaginate
        );
      }
      setChunks(chunkedProjects);
    }
  }, [projectsToPaginate, chunkSize, chunks]);

  const goToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleExit = useCallback(() => {
    setOnboardingOptions({ projects: true });
    userStore.enableOnboarding(true);
  }, [userStore]);

  return {
    projects,
    t,
    enableHints,
    handleExit,
    setFilter,
    filter,
    cancelledCount,
    pausedCount,
    prodCount,
    allCount,
    activeCount,
    chunks,
    projectsToPaginate,
    currentPage,
    goToTop,
    setCurrentPage,
  };
};
