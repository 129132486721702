import { useAuthStore } from 'contexts/AuthContext';
import { useUserStore } from 'contexts/UserContext';
import 'intro.js/introjs.css';
import { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { ShowNotification } from 'tools/showNotification';
import { setOnboardingOptions } from 'tools/onboarding';
import 'containers/CardsContainer/IntroTooltip.css';
import { GetCountries } from 'services/api/getCountries';
import { GetCurrencies } from 'services/api/getCurrencies';
import { ChangeUser } from 'services/api/changeUser';

export const useLocale = () => {
  const userStore = useUserStore();
  const authStore = useAuthStore();
  const { i18n, t } = useTranslation();

  const { currency, country } = userStore?.user;

  const [countries, setCountries] = useState<any>([]);
  const [currencies, setCurrencies] = useState<any>([]);
  const [userCountry, setUserCountry] = useState(country);
  const [userCurrency, setUserCurrency] = useState(currency);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleExit = useCallback(() => {
    setOnboardingOptions({ locale: true });
    userStore.enableOnboarding(true);
  }, [userStore]);

  const [cookies, setCookies] = useCookies(['access', 'refresh']);

  const getParams = useCallback(async () => {
    if (userStore.user) {
      setUserCountry(userStore.user.country);
      setUserCurrency(userStore.user.currency);
    }
  }, [userStore]);

  const getCountries = async () => {
    try {
      const response = await GetCountries({
        access: cookies.access,
        refresh: cookies.refresh,
      });
      setCountries(response);
    } catch (error) {
      setCountries([]);
    }
  };

  const getCurrencies = async () => {
    try {
      const response = await GetCurrencies({
        access: cookies.access,
        refresh: cookies.refresh,
      });
      setCurrencies(response);
    } catch (error) {
      setCurrencies([]);
    }
  };

  const handleSaveLocalPreferences = async (data: any) => {
    setIsDisabled(true);
    try {
      const response = await ChangeUser({ access: cookies.access, data: data });
      userStore.addUser(response.user);
      setUserCountry(response.user.country);
      setUserCurrency(response.user.currency);
      userStore.changeLanguage(response.user.language.lang_code);
      setIsSuccess(true);
      ShowNotification({
        type: 'success',
        children: t('Changes saved successfully'),
      });
      window.location.reload();
    } catch (error) {
      console.error(error);
      ShowNotification({
        type: 'error',
        children: t('An error occurred. Please try again later'),
      });
    }
    setIsDisabled(false);
  };

  useEffect(() => {
    if (authStore.isAuth) {
      getParams();
      getCountries();
      getCurrencies();
    }
  }, [userStore.user]);

  return {
    userStore,
    t,
    handleExit,
    handleSaveLocalPreferences,
    countries,
    currencies,
    setIsDisabled,
    isDisabled,
    isSuccess,
    i18n,
    userCurrency,
    userCountry,
  };
};
