import { LocalPreferencesForm } from 'components/common/Header/LocalPreferences';
import { languages } from 'constants/languages';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import { LABELS, localeMessages } from 'constants/onboarding';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { isOnboardingActive } from 'tools/onboarding';
import 'containers/CardsContainer/IntroTooltip.css';
import classes from './LocalePageContainer.module.scss';
import { useLocale } from './useLocale';

export const LocalePageContainer = () => {
  const {
    userStore,
    t,
    handleExit,
    handleSaveLocalPreferences,
    countries,
    currencies,
    setIsDisabled,
    isDisabled,
    i18n,
    userCurrency,
    userCountry,
  } = useLocale();
  const isMobile = useWindowWidth().isSmallLaptop;
  const { GOT_IT, NEXT, BACK } = LABELS(t);

  const userName = userStore.user?.full_name;
  const enableHints = isOnboardingActive(userStore?.pagesToOnboard?.locale);

  return (
    <div className={classes.container}>
      <Steps
        enabled={isMobile && enableHints}
        steps={localeMessages(userName, t)}
        options={{
          tooltipClass: 'customTooltip',
          doneLabel: GOT_IT,
          nextLabel: NEXT,
          prevLabel: BACK,
        }}
        initialStep={0}
        onComplete={handleExit}
        onExit={handleExit}
      />

      <h2 className={classes.title}>
        {t('Please, confirm your local preferences')}
      </h2>

      {userStore.user && (
        <div className={classes.preference}>
          <LocalPreferencesForm
            onClose={() => {}}
            onSave={handleSaveLocalPreferences}
            noticeText={t(
              'Local settings affect the interface language and prices you see on the platform'
            )}
            onlySaveButton={true}
            disabled={isDisabled}
            setIsDisabled={setIsDisabled}
            countries={countries}
            currencies={currencies}
            languages={languages}
            currentCountry={userCountry}
            currentCurrency={userCurrency}
            currentLanguage={languages.find(
              (item) => item?.lang_code === i18n.language
            )}
          />
        </div>
      )}
    </div>
  );
};
