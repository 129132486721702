import classes from './EmptyDeals.module.scss';
import { NavLink } from 'react-router-dom';
import { Button } from 'components/shared/Button';
import emptyImage from 'assets/img/HotDeals/empty.png';
import { useTranslation } from 'react-i18next';

export const EmptyDeals = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className={classes.container}>
        <div className={classes.inner}>
          <div className={classes.info}>
            <div className={classes.title}>{t('Coming soon')}!</div>
            <div className={classes.text}>
              <p>{t('There are no active Hot Deals')}.</p>
              <p>{t('Stay tuned to catch super sales')}</p>
            </div>

            <NavLink to={'/solutions'} className={classes.button}>
              <Button
                theme="primary"
                className={classes.button}
                onClick={(e) => e.preveentDefault()}
              >
                {t('Back to Solutions')}
              </Button>
            </NavLink>
          </div>
        </div>
      </div>

      <div className={classes.imgWrap}>
        <img src={emptyImage} alt="file" className={classes.img} />
      </div>
    </>
  );
};
