import { RegistrationContainer } from 'containers/Registration';
import { RegistrationRUContainer } from 'containers/RegistrationRU';
import 'styles/main.scss';
import { RUSSIAN_LOCALE } from 'utils';

// todo add localization feature
export const Registration = () => {
  return RUSSIAN_LOCALE ? (
    <RegistrationRUContainer />
  ) : (
    <RegistrationContainer />
  );
};
