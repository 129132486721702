import { useMarketPlaceStore } from 'contexts/marketPlaceContext';
import { useUserStore } from 'contexts/UserContext';
import { useHotDeals } from 'hooks/useHotDeals';
import 'intro.js/introjs.css';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Service from 'services/service';
import { isOnboardingActive, setOnboardingOptions } from 'tools/onboarding';
import { ShowNotification } from 'tools/showNotification';
import 'containers/CardsContainer/IntroTooltip.css';

export const useServiceDetail = () => {
  const userStore = useUserStore();
  const params = useParams();

  const marketPlaceStore = useMarketPlaceStore();
  const singleService = marketPlaceStore?.singleService;

  const [serviceInfo, setServiceInfo] = useState<any>(singleService);
  const [hasError, setHasError] = useState(false);
  const enableHints = isOnboardingActive(
    userStore?.pagesToOnboard?.solutionsDetail
  );

  const handleExit = useCallback(() => {
    setOnboardingOptions({ solutionsDetail: true });
    userStore.enableOnboarding(true);
  }, [userStore]);

  useEffect(() => {
    window?.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const dealses = serviceInfo?.discounts?.find(
    (item: any) => item.type === 'HOT_DEAL' && item.is_active === true
  );
  const { t, i18n } = useTranslation();

  const { getHotDealText } = useHotDeals(t);
  const [isHotDeal, setIsHotDeal] = useState(false);
  const [hotDealDiscountText, setHotDealDiscountText] = useState('');
  let dealsDates = {
    dateDealDate: 0,
    monthEndDate: '',
    yearEndDate: 0,
    active: false,
  };

  const fetchData = async () => {
    if (params?.id) {
      try {
        const response = await Service.getService(params.id);
        setServiceInfo(response?.data);
        marketPlaceStore.loadActiveHotDeals([response?.data]);
        marketPlaceStore.loadDataTimeHotDeals();
        setHasError(false);
      } catch (error: any) {
        setHasError(true);
        const errorMessage =
          error?.response?.data?.detail ||
          error?.message ||
          t('Something went wrong');
        ShowNotification({
          type: 'error',
          children: errorMessage,
        });
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [userStore.user.language, userStore.user.currency]);

  useEffect(() => {
    if (serviceInfo && dealses) {
      setIsHotDeal(true);
      const utcDate = new Date(`${dealses.deal_end_date}`);
      const dateDealDate = utcDate.getDate();
      const monthEndDate = utcDate.toLocaleString(i18n.language, {
        month: 'long',
      });
      const yearEndDate = utcDate.getFullYear();
      const active = true;
      dealsDates = {
        dateDealDate,
        monthEndDate,
        yearEndDate,
        active,
      };
      setHotDealDiscountText(getHotDealText(dealses, serviceInfo));
    }
  }, [serviceInfo, dealses]);

  const highlightCartClass = document.querySelector(
    '.tooltip-cart'
  ) as HTMLElement;

  useEffect(() => {
    if (highlightCartClass) {
      highlightCartClass.style.padding = '5px';
    }
  }, [highlightCartClass]);

  return {
    serviceInfo,
    hasError,
    t,
    i18n,
    enableHints,
    handleExit,
    marketPlaceStore,
    dealsDates,
    hotDealDiscountText,
    userStore,
    dealses,
    isHotDeal,
  };
};
