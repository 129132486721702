import clsx from 'clsx';
import EmptyProducts from 'components/shared/EmptyProducts/EmptyProducts';
import { Fader } from 'components/shared/Fader';
import FormatPrice from 'components/shared/FormatPriceAndCurrency';
import ArrowUpRight from 'components/shared/Icons/ArrowUpRight';
import { PersonIcon } from 'components/shared/Icons/PersonIcon';
import { SearchIcon } from 'components/shared/Icons/SearchIcon';
import { Pagination } from 'components/shared/Pagination';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';
import { InvoiceCardContainer } from '../Finances/InvoiceCard';
import { ProjectsDealStagesContainer } from './DealStage';
import { ProjectGoalsContainer } from './Goals';
import classes from './ProjectsDetail.module.scss';
import { tableHeaders, tabsId, useProjectsDetail } from './useProjectsDetail';

export const ProjectsDetailContainer = observer(() => {
  const {
    project,
    projectName,
    t,
    isTablet,
    shortProjectName,
    source,
    tabs,
    shortSource,
    tabid3,
    tabid2,
    tabid1,
    activeTab,
    setActivTab,
    projectInvoices,
    formattedMessage,
    totalDue,
    tableActive,
    fetchPaginationData,
    currentPage,
    totalPages,
    updateProject,
    manager_img,
  } = useProjectsDetail();

  const projectManager = project?.manager;
  const projectNumber = project?.number;
  const legalEntity = project?.legal_entity;
  const projectCost = project.cost[0].cost;
  const projectCurrencySymbol = project.currency?.symbol;

  return (
    <>
      <TitleAndDescription pageName="ProjectsDetail" title={projectName} />

      <div className={classes.wrapper}>
        <ul className={classes.breadcrumbs}>
          <li className={classes.breadcrumbs__item}>
            <NavLink to={`/projects/`} className={classes.breadcrumbs__link}>
              {t('Projects')}
            </NavLink>
          </li>
          <li className={classes.breadcrumbs__item}>
            <span className={classes.breadcrumbs__link}>
              {isTablet ? shortProjectName : projectName}
            </span>
          </li>
        </ul>

        <h1 className={classes.project__header}>{projectName}</h1>

        <div
          className={clsx(classes.project__link, !isTablet && classes.desktop)}
        >
          <div
            className={clsx(
              classes.project__id,
              source && classes.project__id__source
            )}
          >
            ID {projectNumber}
          </div>

          {source && (
            <NavLink to={source} target="_blank">
              {shortSource}
              <ArrowUpRight className={classes.arrowIcon} />
            </NavLink>
          )}
        </div>

        <div className={classes.grid}>
          <div className={classes.grid__main}>
            <div className={classes.project__tabs} id={tabsId}>
              {tabs.map((tab) => (
                <label key={tab.id} className={classes.project__tab}>
                  <input
                    type="radio"
                    name="project_tab"
                    id="stages"
                    onChange={() => {
                      setActivTab(tab.id);
                    }}
                    checked={activeTab === tab.id}
                  />
                  <div>
                    {tab.label}
                    {tab.id === tabid3 && (
                      <span>{projectInvoices.results.all_count}</span>
                    )}
                  </div>
                </label>
              ))}
            </div>

            {activeTab === tabid1 && (
              <ProjectsDealStagesContainer project={project} />
            )}

            {activeTab === tabid3 && (
              <div className={classes.invoices}>
                <div className={classes.invoices__cards}>
                  <div className={classes.invoices__card}>
                    <div className={classes.invoices__card__header}>
                      {t('Project cost')}
                    </div>
                    <div className={classes.invoices__card__price}>
                      <FormatPrice
                        currency={projectCurrencySymbol}
                        price={project?.solution?.is_free ? 0 : projectCost}
                        withMinus={false}
                      />
                    </div>
                  </div>

                  <div className={classes.invoices__card}>
                    <div className={classes.invoices__card__header}>
                      {t('Total due')}
                    </div>
                    <div className={classes.invoices__card__price}>
                      <FormatPrice
                        currency={projectCurrencySymbol}
                        price={totalDue}
                      />
                    </div>
                    <div className={classes.invoices__card__text}>
                      {formattedMessage}
                    </div>
                  </div>
                </div>

                {projectInvoices.results.sub_invoices.length ? (
                  <Fader active={tableActive}>
                    <div className={classes.invoices__table}>
                      <div className={classes.invoices__table__row}>
                        <div className={classes.invoices__table__cell}>
                          <button className={classes.invoices__table__search}>
                            <SearchIcon />
                          </button>
                        </div>

                        {tableHeaders.map(({ label, icon }) => (
                          <div
                            key={label}
                            className={classes.invoices__table__cell}
                          >
                            <span>{t(label)}</span>
                            {icon && (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <path
                                  d="M4 8H12M2 4H14M6 12H10"
                                  stroke="#9C93AA"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            )}
                          </div>
                        ))}
                      </div>

                      {projectInvoices.results.sub_invoices.map(
                        (invoice: any) => (
                          <InvoiceCardContainer
                            invoice={invoice}
                            className={classes.mini}
                          />
                        )
                      )}

                      {totalPages > 1 && (
                        <div className={classes.pagination}>
                          <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            setPageNumber={fetchPaginationData}
                          />
                        </div>
                      )}
                    </div>
                  </Fader>
                ) : (
                  <EmptyProducts
                    bottomText={t("You don't have any invoices")}
                  />
                )}
              </div>
            )}

            {activeTab === tabid2 && (
              <ProjectGoalsContainer
                project={project}
                updateProject={updateProject}
                tabsId={tabsId}
              />
            )}
          </div>

          <div className={classes.grid__side}>
            {legalEntity && (
              <div className={classes.grid__side__card}>
                <div className={classes.grid__side__card__top}>
                  <PersonIcon />
                  <div className={classes.grid__side__card__text}>
                    <h4 className={classes.grid__side__card__header}>
                      {legalEntity.name}
                    </h4>
                    <p className={classes.grid__side__card__par}>
                      {t('Legal entity')}
                    </p>
                  </div>
                </div>
              </div>
            )}

            <div className={classes.grid__side__card}>
              {projectManager ? (
                <div className={classes.grid__side__card__top}>
                  {!projectManager.image ? (
                    <img
                      src={manager_img}
                      alt=""
                      className={classes.card__grid__card__img}
                    />
                  ) : (
                    <img
                      src={projectManager.image}
                      alt=""
                      className={classes.card__grid__card__img}
                    />
                  )}
                  <div className={classes.grid__side__card__text}>
                    <h4 className={classes.grid__side__card__header_mini}>
                      {projectManager.name}
                    </h4>
                    <p className={classes.grid__side__card__par}>
                      {t('Your personal manager')}
                    </p>
                  </div>
                </div>
              ) : (
                <div className={classes.grid__side__card__top}>
                  <img
                    src={manager_img}
                    alt=""
                    className={classes.card__grid__card__img}
                  />
                  <div className={classes.no_manager}>
                    <p className={classes.no_manager_title}>
                      {t('Assigning manager')}
                    </p>
                    <p className={classes.no_manager_description}>
                      {t('Your personal manager')}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
