import { useUserStore } from 'contexts/UserContext';
import { useWindowWidth } from 'hooks/useWindowWidth';
import 'intro.js/introjs.css';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { marketPlaceStore } from 'stores/marketPlaceStore';
import { isOnboardingActive, setOnboardingOptions } from 'tools/onboarding';
import { RUSSIAN_LOCALE } from 'utils';
import './IntroTooltip.css';
import productListClasses from './ProductList/ProductList.module.scss';
import { defaultSortParams, options, optionsRu } from './utils';

export const useCardsContainer = (title: string) => {
  const { t } = useTranslation();
  const isFavoritesPage = title === t('Favorites');

  const userStore = useUserStore();
  const mobileScreen = useWindowWidth().isSmallLaptop;

  const allProducts = marketPlaceStore.services;
  const enableHints =
    isOnboardingActive(userStore.pagesToOnboard?.solutions) &&
    allProducts?.length;
  const { currency, language, country } = userStore.user || {};

  const products =
    window.location.pathname === '/solutions'
      ? marketPlaceStore.services
      : marketPlaceStore.favoriteServices;

  const pageName =
    window.location.pathname === '/solutions' ? 'Solutions' : 'Favorites';

  const { sortingParams } = marketPlaceStore || {};
  const chunkSize = 8;

  const [isFree, setIsFree] = useState(
    (!isFavoritesPage && sortingParams?.isFree) || false
  );
  const [isNew, setIsNew] = useState(
    (!isFavoritesPage && sortingParams?.isNew) || false
  );
  const [isHot, setIsHot] = useState(
    (!isFavoritesPage && sortingParams?.isHot) || false
  );
  const [isSale, setIsSale] = useState(
    (!isFavoritesPage && sortingParams?.isSale) || false
  );
  const [isTopUp, setIsTopUp] = useState(
    (!isFavoritesPage && sortingParams?.isTopUp) || false
  );
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [productsToShow, setProductsToShow] = useState<any>(products);
  const [serviceTypes, setServiceTypes] = useState<any[]>(
    (!isFavoritesPage && sortingParams?.savedServiceTypes) || []
  );
  const [businessTypes, setBusinessTypes] = useState<any[]>(
    (!isFavoritesPage && sortingParams?.savedBusinessTypes) || []
  );
  const [serviceTypesOfMarketPlace, setServiceTypesOfMarketPlace] =
    useState<any>(serviceTypes);
  const [businessTypesOfMarketplace, setBusinessTypesOfMarketPlace] =
    useState<any>(businessTypes);
  const [budget, setBudget] = useState(
    (!isFavoritesPage && sortingParams?.savedBudget) || 0
  );
  const [priceMin, setPriceMin] = useState(
    !isFavoritesPage ? sortingParams?.savedPriceMin : 0
  );

  const [recommendedServicesIds, setRecommendedServicesIds] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const [isMobile, setIsMobile] = useState(false);
  const [pageSize, setPageSize] = useState(8);
  const [sortSelect, setSortSelect] = useState<any>(
    (!isFavoritesPage &&
      sortingParams.sortSelect?.id &&
      sortingParams.sortSelect) ||
      options(t)[0]
  );
  const [clearBusinessTypes, setClearBusinessTypes] = useState(false);

  const hasRecommendedServices = products.some(
    ({ recommended_service }: any) => recommended_service?.length
  );

  useEffect(() => {
    (RUSSIAN_LOCALE ? optionsRu(t) : options(t))?.forEach((option: any) => {
      if (option?.id === sortSelect?.id) {
        setSortSelect(option);
      }
    });
  }, [sortSelect?.name, userStore.user?.language?.id]);

  const topFilters = {
    defaultIsFree: isFree,
    defaultIsNew: isNew,
    defaultIsHot: isHot,
    defaultIsSale: isSale,
    defaultIsTopUp: isTopUp,
  };

  useEffect(() => {
    if (isFavoritesPage) {
      if (hasRecommendedServices) {
        setRecommendedServicesIds(
          products
            .filter(
              ({ recommended_service }: any) => !!recommended_service?.length
            )
            ?.map((filteredItem: any) => filteredItem?.id)
        );
      }
    }
  }, [hasRecommendedServices, isFavoritesPage]);

  useEffect(() => {
    setServiceTypesOfMarketPlace(marketPlaceStore.serviceTypes);
    // If the user has a business type specified, then we display it first in the filter list.
    if (userStore.user.company_industry && !isFavoritesPage) {
      const indexUserCompanyIndustry = marketPlaceStore.businessTypes.findIndex(
        (item: any) => item.id === userStore.user.company_industry
      );
      if (indexUserCompanyIndustry > -1) {
        setBusinessTypesOfMarketPlace(marketPlaceStore.businessTypes);
      }
    } else {
      setBusinessTypesOfMarketPlace(marketPlaceStore.businessTypes);
    }
  }, [
    country,
    currency?.id,
    language?.id,
    marketPlaceStore?.serviceTypes,
    marketPlaceStore?.businessTypes,
  ]);

  const handleExit = useCallback(() => {
    setOnboardingOptions({ solutions: true });
    userStore.enableOnboarding(true);
  }, [userStore]);

  const pagination = document.querySelector(
    `.${productListClasses.pagination}`
  );

  useEffect(() => {
    if (pagination || products?.length <= 9) {
      setIsMobile(mobileScreen && window.location.pathname !== '/favorites');
    }
  }, [pagination, mobileScreen]);

  const sortParams = defaultSortParams(sortSelect);

  const savedPageParams = {
    savedCurrentPage: currentPage,
    savedChunkSize: chunkSize,
    savedServiceTypes: serviceTypes,
    savedBusinessTypes: businessTypes,
    isFavoritesPage,
    savedBudget: budget,
    savedPriceMin: priceMin,
    savedSortParams: sortParams,
    isFree,
    isNew,
    isHot,
    isSale,
    isTopUp,
    sortSelect,
    clearBusinessTypes: clearBusinessTypes,
  };

  useEffect(() => {
    if (isFavoritesPage) return;
    if (marketPlaceStore.isLoadingServices) return;

    marketPlaceStore.setSortingParams(savedPageParams);
  }, [
    currentPage,
    chunkSize,
    serviceTypes,
    businessTypes,
    isFavoritesPage,
    budget,
    sortParams,
    isFree,
    isNew,
    isHot,
    isSale,
    isTopUp,
    priceMin,
  ]);

  const isServicesLength =
    serviceTypesOfMarketPlace.length > 0 &&
    businessTypesOfMarketplace.length > 0;

  return {
    businessTypes,
    setBusinessTypes,
    isFavoritesPage,
    budget,
    priceMin,
    setPriceMin,
    t,
    pageName,
    isMobile,
    enableHints,
    handleExit,
    serviceTypes,
    setServiceTypes,
    serviceTypesOfMarketPlace,
    businessTypesOfMarketplace,
    setBudget,
    sortSelect,
    topFilters,
    pageSize,
    setProductsToShow,
    setCurrentPage,
    setIsFirstRender,
    setClearBusinessTypes,
    clearBusinessTypes,
    setIsHot,
    setIsNew,
    setIsFree,
    isServicesLength,
    isFirstRender,
    currentPage,
    productsToShow,
    setIsTopUp,
    setIsSale,
    recommendedServicesIds,
    chunkSize,
    setSortSelect,
    setPageSize,
  };
};
