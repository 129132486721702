export enum PASS_MODE {
  OLD = 'oldPass',
  NEW = 'newPass',
  CONFIRM = 'newPassConfirmation',
}

export enum AMOUNT_TYPE {
  MORE = '>',
  LESS = '<',
}

export enum AMOUNT_UNIT {
  CUR = '$',
  PERC = '%',
  NONE = '',
  RUB = '₽',
}

export enum COST_TYPE {
  FROM = 'COST_TYPE_FROM',
  TO = 'COST_TYPE_TO',
}

export enum DEAL_STAGES {
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  PAUSED = 'paused',
  ALL = 'all',
  ACTIVE = 'active',
}

export enum PLATFORM {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
}
