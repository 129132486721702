import { useUserStore } from 'contexts/UserContext';
import 'intro.js/introjs.css';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { financesStore } from 'stores/financesStore';
import { setOnboardingOptions } from 'tools/onboarding';
import { Bill, Filter } from 'types/finances';
import 'containers/CardsContainer/IntroTooltip.css';
import { columnsArray, queryParamsHandler } from './invoiceData';

export const useFinancesContainer = () => {
  const userStore = useUserStore();
  const { setBillsForPayment } = financesStore;

  const data = userStore.invoices;
  const results = data.results;
  const invoices = results.sub_invoices;
  const allInvoices = results.all_count ?? 0;
  const paidInvoices = results.paid_count ?? 0;
  const toPayInvoices = results.to_pay_count ?? 0;
  const cancelledInvoices = results.cancelled_count ?? 0;

  const { t } = useTranslation();
  const [tableActive, setTableActive] = useState(false);
  const [internalInvoices, setInternalInvoices] = useState<any[]>(invoices);
  const itemsPerPage = 10;
  const [filter, setFilter] = useState<Filter>('all');
  const [customPageCount, setCustomPageCount] = useState<number | undefined>(
    undefined
  );
  const [dataLength, setDataLength] = useState(allInvoices);

  useEffect(() => {
    if (filter === 'all') {
      setDataLength(allInvoices);
    } else if (filter === 'paid') {
      setDataLength(paidInvoices);
    } else if (filter === 'topay') {
      setDataLength(toPayInvoices);
    } else if (filter === 'cancelled') {
      setDataLength(cancelledInvoices);
    }
  }, [filter]);

  useEffect(() => {
    if (dataLength) {
      setCustomPageCount(Math.ceil(dataLength / itemsPerPage));
    }
  }, [dataLength]);

  useEffect(() => {
    setInternalInvoices(invoices);
  }, [filter, invoices, results]);

  const getDataByPage = async (currentPage: number | undefined) => {
    const params = queryParamsHandler({
      page: currentPage,
      pageSize: itemsPerPage,
      quickFilter: filter,
    });
    setTableActive(true);
    try {
      await userStore.loadInvoices(params).then((response: any) => {
        setDataLength(response?.count);
      });
    } catch (error) {
      console.error(error);
    } finally {
      setTableActive(false);
    }
  };

  const getDataByFilter = async (filter: any) => {
    const params = queryParamsHandler({
      page: 1,
      pageSize: itemsPerPage,
      quickFilter: filter,
    });
    setTableActive(true);
    try {
      await userStore.loadInvoices(params);
      setFilter(filter);
    } catch (error) {
      console.error(error);
    } finally {
      setTableActive(false);
    }
  };

  const columns = useMemo(() => columnsArray(t), [t]);

  const handleExit = useCallback(() => {
    setOnboardingOptions({ finances: true });
    userStore.enableOnboarding(true);
  }, [userStore]);

  const [showTopUpModal, setShowTopUpModal] = useState(false);
  const [showPaymentBillModal, setShowPaymentBillModal] = useState(false);

  const handleShowTopUpModal = () => {
    setShowTopUpModal(true);
  };

  const handleCloseTopUpModal = () => {
    setShowTopUpModal(false);
  };

  const handleShowPaymentBillModal = (items: Bill[]) => {
    setBillsForPayment(items);
    setShowPaymentBillModal(true);
  };

  const handleClosePaymentBillModal = () => {
    setShowPaymentBillModal(false);
  };

  const tabs = [
    {
      id: 1,
      label: t('Operations history'),
    },
    {
      id: 2,
      label: t('Invoices'),
    },
  ];

  const [activeTab, setActivTab] = useState(tabs[0].id);

  // for the balance replenishment button on mobiles when scrolling
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  // ______

  return {
    userStore,
    handleCloseTopUpModal,
    t,
    handleExit,
    handleShowTopUpModal,
    showTopUpModal,
    scrollPosition,
    activeTab,
    setActivTab,
    tabs,
    internalInvoices,
    handleClosePaymentBillModal,
    showPaymentBillModal,
    handleShowPaymentBillModal,
    filter,
    itemsPerPage,
    getDataByPage,
    customPageCount,
    tableActive,
    columns,
    getDataByFilter,
    cancelledInvoices,
    toPayInvoices,
    paidInvoices,
    allInvoices,
  };
};
