import Icons from '../Icons';
import './Icon.module.scss';
import clsx from 'clsx';
import { UiIconProps } from './UiIcon.props';

const getIcon = (iconName: string): React.FC<React.SVGProps<SVGSVGElement>> => {
  const IconElements = Icons as any;

  return IconElements[iconName];
};

const UiIcon = ({
  name = 'EmptyIcon',
  additionalClassName,
  onKeyPress,
  onClick,
}: UiIconProps) => {
  if (!name) {
    return null;
  }

  const Icon = getIcon(name);

  const transformIconName = () => {
    const divideByCaps = name?.split(/(?=[A-Z])/);
    return divideByCaps.join('-').toLowerCase();
  };

  if (getIcon(name))
    return (
      <div
        className={clsx(
          `global-icon`,
          `icon-${transformIconName()}`,
          additionalClassName
        )}
        onKeyDown={onKeyPress}
        onClick={onClick}
        tabIndex={0}
      >
        <Icon />
      </div>
    );

  return null;
};

export default UiIcon;
