import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { StepOne } from './StepOne';
import classes from './RegistrationForm.module.scss';
import clsx from 'clsx';
import { StepTwo } from './StepTwo';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  registrationStepOneSchema,
  registrationStepTwoSchema,
} from '../../Account/validationSchemas';
import { getGaClientId } from '../../../tools/analytics';
import { RegistrationStepOneProps, RegistrationStepTwoProps } from 'types/auth';

interface Props {
  step: number;
  setStep: (val: number) => void;
  setSendEmail: (val: string) => void;
  setSuccessData: (val: object) => void;
  kidUserInfo: { [key: string]: any };
}

export const RegistrationForm: FC<Props> = ({
  step,
  setStep,
  setSendEmail,
  setSuccessData,
  kidUserInfo,
}) => {
  const { t, i18n } = useTranslation();
  const [schema, setSchema] = useState<any>(registrationStepOneSchema(t));
  const currentLanguage = localStorage.getItem('kitGlobalLng');

  const defaultValues = !!kidUserInfo
    ? {
        fullName: `${kidUserInfo.first_name} ${kidUserInfo.last_name}`,
        email: kidUserInfo.email,
        phone: kidUserInfo.phone.replace(/^\+/, ''),
        password: '123qwe!@#',
      }
    : {};

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setError,
    getValues,
    setValue,
  } = useForm<RegistrationStepTwoProps | RegistrationStepOneProps>({
    resolver: yupResolver(schema as any),
    mode: 'all',
    defaultValues,
  });

  useEffect(() => {
    kidUserInfo && setValue('kid_token', kidUserInfo.kid_token);
  }, []);

  useEffect(() => {
    setSchema(
      step === 1 ? registrationStepOneSchema(t) : registrationStepTwoSchema(t)
    );
  }, [step, currentLanguage]);

  const { fullName, email, phone, companyName, url } = getValues() as any;
  const formRef = useRef(null);
  const [password, setPassword] = useState<string>('');
  const [goals, setGoals] = useState<string[]>([]);
  const [comment, setComment] = useState<string>('');
  const [check, setCheck] = useState(false);
  const [recaptcha, setRecaptcha] = useState('');
  const [passwordErrorDescription, setPasswordErrorDescription] =
    useState<string>('');
  const [companySizes, setCompanySizes] = useState<any>([]);
  const [selectedCompanySize, setSelectedCompanySize] = useState<any>();
  const [businessTypes, setBusinessTypes] = useState<any>([]);
  const [selectedBusinessType, setSelectedBusinessType] = useState<any>();
  const [isErrorStepOne, setIsErrorStepOne] = useState(false);
  const [checkErrors, setCheckErrors] = useState(false);
  const [isStepTwoValid, setIsStepTwoValid] = useState(false);
  const [googleRecaptcha, setGoogleRecaptcha] = useState<any>();

  const setErrorMessage = (field: any, message: string) => {
    setError(field, { type: 'server', message });
  };

  useEffect(() => {
    setSelectedBusinessType(businessTypes[0]);
    setSelectedCompanySize(companySizes[0]);
  }, [i18n.language]);

  const submit = useCallback(
    async (e: any) => {
      const formData = new FormData();

      const getCookiesConsent = getGaClientId();

      setCheckErrors(isValid);

      if (kidUserInfo) {
        formData.append('kid_token', kidUserInfo.token);
      }
      formData.append('full_name', fullName);
      formData.append('email', email);
      formData.append('phone_number', `+${phone}`);
      formData.append(
        'company_size',
        selectedCompanySize?.id || companySizes[0]?.id
      );
      formData.append('company_industry', selectedBusinessType?.id || 1);
      formData.append('password', password);
      if (process.env.REACT_APP_DISABLE_RECAPCHA === 'false') {
        formData.append('g-recaptcha-response', recaptcha);
      }
      formData.append('source_page', window.location?.href);
      formData.append('language', i18n.language);
      if (goals.length > 0) {
        goals.map((goal) => {
          formData.append('goal', goal);
          return null;
        });
      }
      if (comment !== '') {
        formData.append('other_field_value', comment);
      }
      if (check) {
        formData.append('free_consultation', 'on');
      }
      if (companyName) {
        formData.append('company_name', companyName);
      }
      if (url) {
        formData.append('company_link', url);
      }
      // if (gaClientId) {
      //   formData.append('ga_client_id', gaClientId)
      // }
      if (getCookiesConsent) {
        formData.append('ga_client_id', getCookiesConsent);
      }
      //free_consultation
      const requestOptions = {
        method: 'POST',
        body: formData,
        headers: {
          'Platform-Type': process.env.REACT_APP_PLATFORM_TYPE || '',
        },
      };

      fetch(`${process.env.REACT_APP_API}/register/`, requestOptions)
        .then((response) => response.json())
        .then((response) => {
          const { form_errors } = response || {};
          if (process.env.REACT_APP_DISABLE_RECAPCHA === 'false') {
            googleRecaptcha.reset();
          }
          if (form_errors) {
            const {
              full_name,
              email,
              phone_number,
              company_name,
              company_link,
              password,
            } = form_errors || {};

            if (full_name) {
              setErrorMessage('fullName', full_name[0]);
            }
            if (email) {
              setErrorMessage('email', email[0]);
            }
            if (phone_number) {
              setErrorMessage('phone', phone_number[0]);
            }
            if (company_name) {
              setErrorMessage('companyName', company_name[0]);
            }
            if (company_link) {
              setErrorMessage('url', company_link[0]);
            }
            if (password) {
              setErrorMessage('password', password[0]);
              setPasswordErrorDescription(password[0]);
            }
          } else {
            setStep(3);
            setSendEmail(email);
            setSuccessData(response.data);
          }
        });
    },
    [
      formRef,
      fullName,
      email,
      phone,
      companyName,
      url,
      password,
      goals,
      check,
      comment,
      recaptcha,
      googleRecaptcha,
    ]
  );

  useEffect(() => {
    setIsErrorStepOne(!!errors?.email);
  }, [
    errors?.fullName,
    errors?.email,
    errors?.phone,
    errors?.password,
    errors?.iAgree,
  ]);

  useEffect(() => {
    if (checkErrors) {
      if (isErrorStepOne) {
        setStep(1);
      }
    }
  }, [checkErrors, setStep, isErrorStepOne]);

  return (
    <form
      ref={formRef}
      onSubmit={handleSubmit(submit)}
      className={classes.form}
    >
      <div className={clsx(classes.step, step === 1 && classes.active)}>
        <StepOne
          control={control}
          errors={errors}
          password={password}
          setPassword={setPassword}
          setStep={setStep}
          passwordErrorDescription={passwordErrorDescription}
          setBusinessTypes={setBusinessTypes}
          setCompanySizes={setCompanySizes}
          isValid={isValid}
          readonly={!!kidUserInfo}
        />
      </div>
      <div className={clsx(classes.step, step === 2 && classes.active)}>
        <StepTwo
          setStep={setStep}
          check={check}
          setCheck={setCheck}
          setRecaptcha={setRecaptcha}
          recaptcha={recaptcha}
          businessTypes={businessTypes}
          setSelectedBusinessType={setSelectedBusinessType}
          companySizes={companySizes}
          setSelectedCompanySize={setSelectedCompanySize}
          control={control}
          errors={errors}
          isValid={isValid}
          selectedBusinessType={selectedBusinessType}
          selectedCompanySize={selectedCompanySize}
          isStepTwoValid={isStepTwoValid}
          setIsStepTwoValid={setIsStepTwoValid}
          googleRecaptcha={googleRecaptcha}
          setGoogleRecaptcha={setGoogleRecaptcha}
        />
      </div>
    </form>
  );
};
