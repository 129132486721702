import React from 'react';
import { cartStore } from 'stores/cartStore';

const CartContext = React.createContext<any>(null);

export const CartProvider = ({ children }: any) => (
  <CartContext.Provider value={cartStore}>{children}</CartContext.Provider>
);

export const useCartStore = () => {
  const context = React.useContext(CartContext);
  if (context === null) {
    throw new Error(
      'You have forgotten to wrap your root component with CartProvider'
    );
  }
  return context;
};
