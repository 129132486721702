import { Request } from 'tools/request';

interface Props {
  access: string;
  data: any;
}

export async function ChangeUser({ access, data }: Props) {
  return await Request({
    type: 'PUT',
    url: `${process.env.REACT_APP_API}/api/v1/users/`,
    isProtected: true,
    access: access,
    data: data,
  });
}
