import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { AcceptCookies } from 'services/api/acceptCookies';
import { DeclineCookies } from 'services/api/declineCookies';
import { ShowNotification } from 'tools/showNotification';

export const useCookieNotice = () => {
  const { t, i18n } = useTranslation();
  const [cookies, setCookies] = useCookies(['access', 'refresh']);

  const handleAcceptCookies = async () => {
    try {
      const result = await AcceptCookies({
        cookie_groups_varnames: 'platform_analytics',
        access: cookies.access,
        refresh: cookies.refresh,
      });
      const { name, value, max_age, domain, secure, httponly, samesite } =
        result.cookie_data;

      setCookies(name, value, {
        maxAge: max_age,
        domain: domain,
        secure: secure,
        httpOnly: httponly,
        sameSite: samesite,
      });
    } catch (error) {
      ShowNotification({
        type: 'error',
        children: t('Unknown error. Please, try again later'),
      });
    }
  };

  const handleDeclineCookies = async () => {
    try {
      const result = await DeclineCookies({
        cookie_groups_varnames: 'platform_analytics',
        access: cookies.access,
        refresh: cookies.refresh,
      });
      const { name, value, max_age, domain, secure, httponly, samesite } =
        result.cookie_data;

      setCookies(name, value, {
        maxAge: max_age,
        domain: domain,
        secure: secure,
        httpOnly: httponly,
        sameSite: samesite,
      });
    } catch (error) {
      ShowNotification({
        type: 'error',
        children: t('Unknown error. Please, try again later'),
      });
    }
  };

  return { t, i18n, handleAcceptCookies, handleDeclineCookies };
};
