import clsx from 'classnames';
import FormatPrice from 'components/shared/FormatPriceAndCurrency';
import ArrowUpRight from 'components/shared/Icons/ArrowUpRight';
import i18n from 'i18n/i18n';
import { NavLink } from 'react-router-dom';
import { ProjectsDealStagesContainer } from '../DealStage';
import classes from './Card.module.scss';
import { useProjectCard } from './useProjectCard';

export const ProjectCard = ({ project }: { project: any }) => {
  const { tagClass, tagText, source, shortSource, t, manager_img } =
    useProjectCard(project);

  return (
    <div className={classes.card}>
      <div className={classes.card__top_mobile}>
        {tagClass && (
          <div className={clsx(classes.card__tag, tagClass)}>{tagText}</div>
        )}
        <NavLink to={`/projects/${project.id}`} className={classes.card__link}>
          <h3 className={classes.card__header}>
            {project[`name_${i18n.language}`] ?? project.name}
          </h3>
        </NavLink>

        <div className={classes.card__top__bottom}>
          <div className={classes.card__id}>ID {project.number}</div>
          {source && (
            <span className={classes.card__top__bottom__separate}></span>
          )}

          {source && (
            <NavLink
              to={source}
              target="_blank"
              className={classes.card__source__mobile}
            >
              {shortSource}
              <ArrowUpRight
                className={classes.card__arrowIcon}
                fill="#8362F3"
              />
            </NavLink>
          )}
        </div>
      </div>

      <div className={classes.card__description}>
        <div className={classes.card__top}>
          <NavLink
            to={`/projects/${project.id}`}
            className={classes.card__link}
          >
            <h3 className={classes.card__header}>
              {project[`name_${i18n.language}`] ?? project.name}
            </h3>
          </NavLink>

          <div className={classes.card__tags}>
            {tagClass && (
              <div className={clsx(classes.card__tag, tagClass)}>{tagText}</div>
            )}
            <div className={classes.card__id}>ID {project.number}</div>
          </div>
        </div>

        {source && (
          <div>
            <NavLink
              className={classes.card__source}
              to={source}
              target="_blank"
            >
              {shortSource}
              <ArrowUpRight
                className={classes.card__arrowIcon}
                fill="#8362F3"
              />
            </NavLink>
          </div>
        )}
      </div>

      <div className={classes.card__grid}>
        <div className={classes.card__grid__card}>
          <div className={classes.card__grid__card__header}>{t('Stages')}</div>

          <div className={classes.map}>
            <div className={classes.step}>
              <ProjectsDealStagesContainer project={project} />
            </div>
          </div>
        </div>

        <div className={classes.card__grid__card}>
          <div className={classes.card__grid__card__header}>
            {t('Project cost')}
          </div>

          <div className={classes.card__grid__card__coast}>
            {project.cost?.reduce((currentValue: number, coast: any) => {
              const isFree = project.solution.is_free;

              return (
                <FormatPrice
                  currency={project.currency.symbol}
                  price={isFree ? 0 : Number(coast.cost) + currentValue}
                  withMinus={false}
                />
              );
            }, 0)}
          </div>

          <div className={classes.card__grid__card__text}>
            {t(
              'Please note! The project cost is subject to change based on work scope and ad budget'
            )}
          </div>
        </div>

        <div
          className={clsx(
            classes.card__grid__card,
            classes.card__grid__card_manager
          )}
        >
          {project.manager ? (
            <>
              {!project.manager.image ? (
                <img
                  src={manager_img}
                  alt=""
                  className={classes.card__grid__card__img}
                />
              ) : (
                <img
                  src={project.manager.image}
                  alt=""
                  className={classes.card__grid__card__img}
                />
              )}

              <div className={classes.card__grid__card_manager_header}>
                <h4 className={classes.card__grid__card__title}>
                  {project.manager.name}
                </h4>
                <div className={classes.card__grid__card__header}>
                  {t('Your personal manager')}
                </div>
              </div>
            </>
          ) : (
            <div className={classes.card__grid__card_manager_header}>
              <img
                src={manager_img}
                alt=""
                className={classes.card__grid__card__img}
              />
              <p className={classes.no_manager_title}>
                {t('Assigning manager')}
              </p>
              <p className={classes.no_manager_description}>
                {t('Your personal manager')}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
