import { marketPlaceStore } from 'stores/marketPlaceStore';
import {
  solutionsMessagesWithoutPaginationAndWithoutServices,
  solutionsMessagesWithoutPaginationWithServices,
  solutionsMessagesWithPaginationWithoutServices,
  solutionsMessagesWithPaginationWithServices,
} from 'constants/onboarding';
import { useTranslation } from 'react-i18next';

export const useDefineStepsOnboarding = (isFavoritesPage: boolean) => {
  const { t } = useTranslation();

  // Below is the code to define the onboarding steps
  const { is_free, is_new, is_hot, is_sale, is_topup } =
    marketPlaceStore.isTopServices;

  const {
    is_free_favorite,
    is_hot_favorite,
    is_new_favorite,
    is_sale_favorite,
    is_topup_favorite,
  } = marketPlaceStore?.isFavoriteServices;

  const isTopServices = is_free || is_new || is_hot || is_sale || is_topup;
  const isFavoriteServices =
    is_free_favorite ||
    is_new_favorite ||
    is_hot_favorite ||
    is_sale_favorite ||
    is_topup_favorite;

  const steps = isFavoritesPage
    ? marketPlaceStore.allCount > 8
      ? isFavoriteServices
        ? solutionsMessagesWithPaginationWithServices(t)
        : solutionsMessagesWithPaginationWithoutServices(t)
      : isFavoriteServices
        ? solutionsMessagesWithoutPaginationWithServices(t) // When there is no pagination but has favorite services
        : solutionsMessagesWithoutPaginationAndWithoutServices(t) // When there is no pagination and no favorite services
    : marketPlaceStore.allCount > 8
      ? isTopServices
        ? solutionsMessagesWithPaginationWithServices(t)
        : solutionsMessagesWithPaginationWithoutServices(t)
      : isTopServices
        ? solutionsMessagesWithoutPaginationWithServices(t) // When there is no pagination but has top services
        : solutionsMessagesWithoutPaginationAndWithoutServices(t); // When there is no pagination and no top services

  //________________________________

  return { steps };
};
