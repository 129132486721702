import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Request } from 'tools/request';
import { ShowNotification } from 'tools/showNotification';
import { FormInputsPersonal } from 'types/auth';
import { personalSchema } from '../validationSchemas';
import { DefaultValue } from './Personal.props';

export const usePersonal = (defaultValue: DefaultValue, getParams: any) => {
  const { t, i18n } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputsPersonal>({
    resolver: yupResolver(personalSchema),
    mode: 'onChange',
    defaultValues: {
      fullName: defaultValue.fullName,
      email: defaultValue.email,
      phone: defaultValue.phone,
    },
  });

  const [cookies, setCookies] = useCookies(['access', 'refresh']);
  const formRef = useRef(null);

  const [fullName, setFullName] = useState<string>(defaultValue.fullName);
  const [phone, setPhone] = useState<string | null>(defaultValue.phone);

  const [disableSubmit, setDisableSubmit] = useState(true);

  const email = defaultValue?.email;

  const onSubmit = async (e: any) => {
    const formValues = new FormData();

    formValues.append('full_name', fullName);
    formValues.append('email', email);
    formValues.append('phone_number', `+${phone}`);

    try {
      const result = await Request({
        type: 'PUT',
        url: `${process.env.REACT_APP_API}/api/v1/users/`,
        isProtected: true,
        access: cookies.access,
        data: formValues,
      });
      if (result.status !== 400) {
        getParams();

        setDisableSubmit(false);
        ShowNotification({
          type: 'success',
          children: t('Profile successfully changed'),
        });
      }
    } catch (e: any) {
      getParams();
      const result_data = e.response.data;
      if ('phone_number' in result_data) {
        setDisableSubmit(true);
      } else {
        setDisableSubmit(true);
        ShowNotification({
          type: 'error',
          children: t('Something went wrong'),
        });
      }
    }
  };

  useEffect(() => {
    if (fullName !== '' && !errors.fullName && phone && phone.length > 10) {
      if (fullName !== defaultValue.fullName || phone !== defaultValue.phone) {
        setDisableSubmit(false);
      } else {
        setDisableSubmit(true);
      }
    } else {
      setDisableSubmit(true);
    }
  }, [errors?.fullName, fullName, phone]);

  return {
    t,
    i18n,
    formRef,
    handleSubmit,
    onSubmit,
    control,
    errors,
    setFullName,
    email,
    disableSubmit,
    setPhone,
  };
};
