import clsx from 'clsx';
import classes from 'containers/CardsContainer/ProductList/ProductCard/ProductCard.module.scss';
import { useCartStore } from 'contexts/CartContext';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Button } from '../index';

const AddToCartButton = observer(({ data }: { data: any }) => {
  const cartStore = useCartStore();
  const count = cartStore.getServiceCountInCart(data?.id);
  const [countInCart, setCountInCart] = useState(count || 0);
  const [isDisabledButton, setDisabledButton] = useState(false);

  useEffect(() => {
    setCountInCart(count);
  }, [count, data]);

  const addToCart = async () => {
    if (!isDisabledButton) {
      setDisabledButton(true);
      try {
        await cartStore.addToCart({
          serviceId: data.id,
        });
      } finally {
        setDisabledButton(false);
      }
    }
  };

  const removeFromCart = async () => {
    if (!isDisabledButton) {
      setDisabledButton(true);
      try {
        await cartStore.removeFromCart({
          serviceId: data.id,
        });
      } finally {
        setDisabledButton(false);
      }
    }
  };

  return (
    <Button
      onClick={countInCart > 0 ? undefined : addToCart}
      theme="default"
      className={clsx(
        classes.button,
        countInCart > 0 ? classes['in-cart'] : classes['not-in-cart']
      )}
      disabled={isDisabledButton}
    >
      {countInCart > 0 ? (
        <>
          <div className={classes['in-cart__btn']} onClick={removeFromCart}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
            >
              <path
                d="M4.58331 11H17.4166"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>

          {t('In your Cart')}

          <div
            className={clsx(
              classes.counter,
              countInCart > 9 && classes.counterMedium
            )}
          >
            {countInCart}
          </div>
          <div className={classes['in-cart__btn']} onClick={addToCart}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
            >
              <path
                d="M11 4.58337V17.4167M4.58331 11H17.4166"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </>
      ) : (
        t('Add to Cart')
      )}
    </Button>
  );
});

export default AddToCartButton;
