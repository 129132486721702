import { useEffect } from 'react';

const DISABLE_SCROLLING_CLASS = 'open-modal';
const HIDDEN_CLASS = 'open-modal';

export const useOpenMenu = (dependency: any = true) => {
  useEffect(() => {
    if (dependency) {
      const body = window.document.querySelector('body');
      const isBodyScrollable = !body?.classList.contains(
        DISABLE_SCROLLING_CLASS
      );
      const header = window.document.querySelector('header');
      const isHeaderHidden = !header?.classList.contains(
        DISABLE_SCROLLING_CLASS
      );

      if (isBodyScrollable && isHeaderHidden) {
        body?.classList.add(DISABLE_SCROLLING_CLASS);
        header?.classList.add(HIDDEN_CLASS);

        return () => {
          body?.classList.remove(DISABLE_SCROLLING_CLASS);
          header?.classList.remove(HIDDEN_CLASS);
        };
      }
    }
  }, [dependency]);
};
