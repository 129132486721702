import { FC, useEffect, useState } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';

import { Navigation } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';

import clsx from 'clsx';
import { ProductCard } from 'containers/CardsContainer/ProductList/ProductCard';
import classes from './RecommendedService.module.scss';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { queryParamsHandler } from '../CardsContainer/utils';
import { marketPlaceStore } from 'stores/marketPlaceStore';
import { userStore } from 'stores/userStore';
import Service from 'services/service';
import YouMayAlsoNeedSkeleton from 'components/shared/Skeleton/YouMayAlsoNeedSkeleton/YouMayAlsoNeedSkeleton';
import { shuffleArray } from 'tools/utils';

interface Props {
  ids?: number[];
  title?: string;
  nameSource?: string;
}

export const RecommendedService: FC<Props> = observer(
  ({ ids, title, nameSource }) => {
    const { t } = useTranslation();
    const [products, setProducts] = useState([]);
    const { currency, language } = userStore.user;
    const innerProducts = marketPlaceStore?.services?.filter(
      ({ is_active }: any) => is_active
    );
    const params = queryParamsHandler(
      {
        ids,
      },
      true
    );

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      setIsLoading(true);

      Service.getRecommendedServices(params)
        .then((services: any) => {
          const newProducts = services?.data?.results?.length
            ? services?.data?.results
            : innerProducts;

          setProducts(shuffleArray(newProducts));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }, [currency?.id, language?.id]);

    return (
      <div className={classes.wrapper}>
        <h3 className={classes.title}>
          {title ? title : t('Others usually choose')}
        </h3>
        {isLoading ? (
          <YouMayAlsoNeedSkeleton />
        ) : (
          <div className={classes['swiper-wrapper']}>
            <Swiper
              slidesPerView={'auto'}
              spaceBetween={20}
              navigation={true}
              modules={[Navigation]}
              className={clsx(classes.swiper)}
              watchSlidesProgress
              loop={false}
              scrollbar={{ draggable: true }}
              onNavigationNext={(options: any) => {
                if (
                  options?.visibleSlidesIndexes[
                    options?.visibleSlidesIndexes.length - 1
                  ] ===
                  products.length - 1
                ) {
                  const nextButton = document.querySelector(
                    '.swiper-button-next'
                  );
                  if (nextButton)
                    nextButton.classList.add('swiper-button-disabled');
                }
              }}
            >
              <div>
                {products?.length
                  ? products?.map((item: any) => (
                      <SwiperSlide
                        className={classes['swiper-slide']}
                        key={item.id}
                      >
                        <div className={clsx(classes.content)}>
                          <ProductCard
                            isSmall={true}
                            data={item}
                            hotDeals={marketPlaceStore.dataTimeHotDeals}
                            nameSource={nameSource}
                          />
                        </div>
                      </SwiperSlide>
                    ))
                  : null}
              </div>
            </Swiper>
          </div>
        )}
      </div>
    );
  }
);
