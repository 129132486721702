import { useTranslation } from 'react-i18next';
import clsx from 'classnames';
import classes from './Filters.module.scss';
import { FiltersProps } from './Filters.props';
import { DealStageType } from 'constants/dealStatuses';

export const Filters = ({
  all,
  active,
  completed,
  paused,
  cancelled,
  filter,
  setFilter,
}: FiltersProps) => {
  const { t } = useTranslation();

  const filterData = [
    {
      key: 'all',
      label: t('All'),
      count: all,
      disabled: all === 0,
    },
    {
      key: 'active',
      label: t('Active'),
      count: active,
      disabled: active === 0,
    },
    {
      key: 'completed',
      label: t('Completed'),
      count: completed,
      disabled: completed === 0,
    },
    {
      key: 'paused',
      label: t('Paused'),
      count: paused,
      disabled: paused === 0,
    },
    {
      key: 'cancelled',
      label: t('Cancelled'),
      count: cancelled,
      disabled: cancelled === 0,
    },
  ];

  return (
    <div className={classes.wrapper}>
      {filterData.map(({ key, label, count, disabled }) => (
        <div
          key={key}
          role="button"
          tabIndex={-1}
          onClick={() => {
            if (!disabled) {
              setFilter(key as DealStageType);
            }
          }}
          className={clsx(
            classes.filter,
            filter === key && classes.active,
            disabled && classes.disabled
          )}
        >
          {label}
          <div
            className={clsx(classes.count, count > 9 && classes.countMedium)}
          >
            {count}
          </div>
        </div>
      ))}
    </div>
  );
};
