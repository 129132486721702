import clsx from 'classnames';
import { Fader } from 'components/shared/Fader';
import FilterTableIcon from 'components/shared/Icons/FilterTableIcon';
import { Pagination } from 'components/shared/Pagination';
import { SORT_BY } from 'constants/queryParamTypes';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Row, usePagination, useSortBy, useTable } from 'react-table';
import { handleDownload } from 'tools/fileDownload';
import { RUSSIAN_LOCALE } from 'utils';
import MainContent from './MainContent/MainContent';
import MobileOptions from './MobileOptions/MobileOptions';
import classes from './Table.module.scss';
import { TableProps } from './TableProps';

const Table = ({
  data,
  columns,
  tableActive,
  additionalClassNames,
  id,
  customPageCount,
  getDataByPage,
  defaulPageSize,
  mobileModeTitle,
  filter,
  handleShowModalOnButton,
}: TableProps) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    previousPage,
    prepareRow,
    setPageSize,
    pageCount,
    page,
    nextPage,
    gotoPage,
    state: { pageIndex },
  } = useTable({ columns, data }, useSortBy, usePagination);

  const [currentPage, setCurrentPage] = useState(pageIndex + 1);
  const [sortedData, setSortedData] = useState<any>(page);
  const [sortDirections, setSortDirections] = useState<{
    [key: string]: 'desc' | 'asc';
  }>({});

  useEffect(() => {
    setSortedData(page);
  }, [page, filter]);

  const handleSort = (type: string, columnId?: string) => {
    const currentDirection = sortDirections[columnId || type] || 'asc';
    const newSortDirection: 'desc' | 'asc' =
      currentDirection === 'asc' ? 'desc' : 'asc';
    let sortedPage;

    if (type === SORT_BY.DATE || columnId === 'issued') {
      sortedPage = _.orderBy(
        sortedData,
        [
          (row: Row<{}>) => {
            const dateString = row.values?.issued?.value;
            const parts = dateString.split('.');
            const day = parseInt(parts[0], 10);
            const month = parseInt(parts[1], 10) - 1;
            const year = parseInt(parts[2], 10);
            const date = new Date(year, month, day);
            return date.getTime();
          },
        ],
        [newSortDirection]
      );
    } else if (
      type === SORT_BY.NAME &&
      (columnId === 'projects' ||
        columnId === 'invoices' ||
        columnId === 'action')
    ) {
      sortedPage = _.orderBy(
        sortedData,
        [
          (row: Row<{}>) => {
            if (columnId === 'action') {
              return row.values[columnId]?.button?.text;
            }

            return row.values[columnId || 'projects']?.value;
          },
        ],
        [newSortDirection]
      );
    } else if (columnId === 'amount') {
      sortedPage = _.orderBy(
        sortedData,
        [
          (row: Row<{}>) => {
            const valueToNumber = Number(
              row.values.amount.value.replace(/\s/g, '')
            );

            return Number(valueToNumber);
          },
        ],
        [newSortDirection]
      );
    }

    const newSortDirections = {
      ...sortDirections,
      [columnId || type]: newSortDirection,
    };

    setSortedData(sortedPage);
    setSortDirections(newSortDirections);
  };

  useEffect(() => {
    setPageSize(defaulPageSize || 10);
  }, [defaulPageSize, setPageSize]);

  useEffect(() => {
    getDataByPage && getDataByPage(currentPage);
  }, [currentPage]);

  useEffect(() => {
    gotoPage(currentPage - 1);
  }, [currentPage, gotoPage, pageIndex]);

  return (
    <>
      <Fader active={tableActive as boolean}>
        <div
          className={clsx(
            classes.table,
            additionalClassNames && String(additionalClassNames)
          )}
          id={id}
        >
          <MobileOptions
            handleSort={handleSort}
            mobileModeTitle={mobileModeTitle}
          />

          <table {...getTableProps()}>
            <thead>
              {headerGroups?.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className={classes.table__row}
                >
                  <th className={classes.table__cell}></th>
                  {headerGroup?.headers?.map((column: any) => {
                    if (column?.header) {
                      return (
                        <th
                          className={classes.table__cell}
                          {...column.getHeaderProps()}
                        >
                          {column.render('header')}

                          <button
                            className={classes.table__sort}
                            onClick={() => {
                              handleSort(SORT_BY.NAME, column.id);
                            }}
                          >
                            <FilterTableIcon />
                          </button>
                        </th>
                      );
                    } else return null;
                  })}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {sortedData?.map((row: any) => {
                prepareRow(row);

                return (
                  <tr {...row.getRowProps()} className={classes.table__content}>
                    {row?.cells?.map((cell: any) => {
                      const { value } = cell;

                      return (
                        <td
                          {...cell.getCellProps()}
                          className={classes.table__cell}
                        >
                          <div className={classes.table__cell__container}>
                            <div className={classes.table__header}>
                              {value?.currency?.symbol && (
                                <span className={classes.currency}>
                                  {value?.currency?.symbol}
                                </span>
                              )}

                              <MainContent
                                value={value}
                                handleDownload={handleDownload}
                              />
                            </div>

                            <div className={value?.description?.className}>
                              {value?.description?.text}
                            </div>

                            {value?.button && (
                              <button
                                className={clsx(
                                  classes.table__button,
                                  value?.button?.className?.length &&
                                    String(value?.button?.className),
                                  value?.button?.cancelled &&
                                    classes.table__button_cancelled,
                                  RUSSIAN_LOCALE &&
                                    classes[value?.button?.status]
                                )}
                                disabled={value?.button?.disabled}
                                onClick={() =>
                                  handleShowModalOnButton
                                    ? handleShowModalOnButton([
                                        {
                                          serial_id: value.serial_id,
                                          project_number: value.project_number,
                                          amount: value.amount,
                                          project_name: value.project_name,
                                        },
                                      ])
                                    : handleDownload(
                                        value?.button?.filePath as string,
                                        true
                                      )
                                }
                              >
                                {value?.button?.text}
                              </button>
                            )}
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Fader>

      {(customPageCount || pageCount) > 1 && (
        <div className={classes.pagination}>
          <Pagination
            currentPage={currentPage}
            totalPages={customPageCount || pageCount}
            setPageNumber={setCurrentPage}
            nextPage={nextPage}
            prevPage={previousPage}
          />
        </div>
      )}
    </>
  );
};

export default Table;
