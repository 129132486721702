import { toast } from 'react-toastify';
import { Toast } from 'components/shared/Toast';
import { cartStore } from '../stores/cartStore';

interface Props {
  type: 'success' | 'warning' | 'error';
  children?: any;
  toastProps?: any;
}

export function ShowNotification({ type, children, toastProps }: Props) {
  const cartButtonSize = cartStore.fixedButtonSize;

  return toast(<Toast type={type}>{children}</Toast>, {
    ...toastProps,
    className:
      cartButtonSize !== 120 ? 'toast-container' : 'toast-container-big',
  });
}
