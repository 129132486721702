import { useEventListener } from 'hooks/useEventListener';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Search from 'services/search';
import { ShowNotification } from 'tools/showNotification';

export const useSearchComponent = (setOpenSearch: (val: boolean) => void) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [searchSolutions, setSearchSolutions] = useState<any>([]);
  const [searchProjects, setSearchProjects] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const [focus, setFocus] = useState(false);
  const ref = useRef<any>(null);
  const navigate = useNavigate();
  const [responsible, setResponsible] = useState(false);

  const getSearchResults = async () => {
    try {
      setResponsible(false);

      // Encoding the '&' symbol in the search string
      const encodedSearch = search.replace(/&/g, '%26');
      const searchQueries = encodedSearch.split('&').map((q) => q.trim());
      let combinedSolutions: any = [];
      let combinedProjects: any = [];

      if (searchQueries?.length) {
        for (const query of searchQueries) {
          const [responseSolutions, responseProjects] = await Promise.all([
            query && Search.getSearchResults('solutions', query),
            query && Search.getSearchResults('projects', query),
          ]);

          if (responseSolutions) {
            combinedSolutions = [
              ...combinedSolutions,
              ...responseSolutions?.data?.results,
            ];
          }

          if (responseProjects) {
            combinedProjects = [
              ...combinedProjects,
              ...responseProjects?.data?.results,
            ];
          }
        }
      } else {
        const [responseSolutions, responseProjects] = await Promise.all([
          Search.getFastSearchResults(search.trim()),
          Search.getFastSearchResults(search.trim()),
        ]);

        if (responseSolutions) {
          combinedSolutions = responseSolutions?.data?.results;
        }

        if (responseProjects) {
          combinedProjects = responseProjects?.data?.results;
        }
      }

      setSearchSolutions(combinedSolutions);
      setSearchProjects(combinedProjects);

      if (combinedSolutions.length || combinedProjects.length) {
        setResponsible(true);
      }
    } catch (error) {
      ShowNotification({
        type: 'error',
        children: t('Unknown error. Please, try again later'),
      });
    }
  };

  useEffect(() => {
    if (search) {
      getSearchResults();
    }
  }, [search]);

  const handleClick = (e: any) => {
    if (ref.current && !ref.current?.contains(e.target) && !focus) {
      setOpen(false);
    }
  };
  useEventListener('click', handleClick);

  const searchNavigate = (e: any) => {
    if (e.key === 'Enter' && responsible && search) {
      navigate(
        `/search-results#from=${encodeURIComponent(
          window.location.pathname
        )}&search=${encodeURIComponent(search)}`
      );
      setOpen(false);
      setOpenSearch(false);
    }
  };

  const clearSearch = () => {
    setSearch('');
    getSearchResults();
    setOpen(false);
    setResponsible(false);
  };

  return {
    ref,
    search,
    setSearch,
    t,
    setOpen,
    setFocus,
    searchNavigate,
    clearSearch,
    open,
    searchSolutions,
    setSearchSolutions,
    searchProjects,
  };
};
