import clsx from 'clsx';
import { Pagination } from 'components/shared/Pagination';
import { TitleAndDescription } from 'components/shared/TitleAndDescription/TitleAndDescription';
import { LABELS, projectsHints } from 'constants/onboarding';
import 'containers/CardsContainer/IntroTooltip.css';
import { EmptyItems } from 'containers/EmptyItems';
import { useWindowWidth } from 'hooks/useWindowWidth';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import { observer } from 'mobx-react-lite';
import { ProjectCard } from './Card';
import { Filters } from './Filters';
import classes from './Projects.module.scss';
import { useProjects } from './useProjects';

export const ProjectsContainer = observer(() => {
  const {
    projects,
    t,
    enableHints,
    handleExit,
    setFilter,
    filter,
    cancelledCount,
    pausedCount,
    prodCount,
    allCount,
    activeCount,
    chunks,
    projectsToPaginate,
    currentPage,
    goToTop,
    setCurrentPage,
  } = useProjects();
  const isMobile = useWindowWidth().isSmallLaptop;

  const { GOT_IT, NEXT, BACK } = LABELS(t);

  return (
    <>
      <TitleAndDescription pageName={'Projects'} />

      <Steps
        enabled={isMobile && enableHints}
        steps={projectsHints(t)}
        options={{
          tooltipClass: 'customTooltip',
          showBullets: false,
          doneLabel: GOT_IT,
          nextLabel: NEXT,
          prevLabel: BACK,
        }}
        initialStep={0}
        onComplete={() => {
          handleExit();
        }}
        onExit={handleExit}
      />

      <div
        className={clsx(
          classes.wrapper,
          projects.length === 0 && 'empty-background'
        )}
      >
        <div style={{ position: 'relative', zIndex: 1 }}>
          <div className={classes.title}>
            {t('Projects')}
            <span>{allCount}</span>
          </div>

          <Filters
            all={allCount}
            active={activeCount}
            completed={prodCount}
            paused={pausedCount}
            cancelled={cancelledCount}
            filter={filter}
            setFilter={setFilter}
          />
        </div>

        <div className={classes.grid}>
          {(chunks.length > 1 ? chunks[currentPage - 1] : chunks[0])?.map(
            (project: any) => (
              <ProjectCard key={project.number} project={project} />
            )
          )}

          {projects.length === 0 && (
            <EmptyItems
              isBackground={false}
              title={'You have no projects'}
              description={
                'To initiate a project, select a Solution and add it to cart'
              }
            />
          )}
        </div>

        {projectsToPaginate.length > 3 && (
          <div className={classes.pagination}>
            <Pagination
              currentPage={currentPage}
              totalPages={chunks.length}
              setPageNumber={setCurrentPage}
              extraAction={() => goToTop()}
            />
          </div>
        )}
      </div>
    </>
  );
});
