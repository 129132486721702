import { Suspense } from 'react';
import { useLoaderData, Await } from 'react-router-dom';
import CartSkeleton from '../shared/Skeleton/CartSkeleton/CartSkeleton';

export const LoaderWrapperCart = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const loadedData: any = useLoaderData();

  return (
    <Suspense fallback={<CartSkeleton />}>
      <Await resolve={loadedData?.loader}>{children}</Await>
    </Suspense>
  );
};
