import clsx from 'clsx';
import { Button } from 'components/shared/Button';
import UiIcon from 'components/shared/Icon';
import { Modal } from 'components/shared/Modal';
import { ModalMobile } from 'components/shared/ModalMobile';
import loader from 'components/shared/PageLoader/loader.json';
import { Pagination } from 'components/shared/Pagination';
import Select from 'components/shared/SelectComponent';
import ProductListSkeleton from 'components/shared/Skeleton/ProductListSkeleton/ProductListSkeleton';
import { useWindowWidth } from 'hooks/useWindowWidth';
import Lottie from 'lottie-react';
import { useEffect, useState } from 'react';
import request from 'assets/img/Marketplace/request.png';
import request_ru from 'assets/img/Marketplace/request_ru.png';
import { useTranslation } from 'react-i18next';
import { marketPlaceStore } from 'stores/marketPlaceStore';
import { userStore } from 'stores/userStore';
import { RUSSIAN_LOCALE } from 'utils';
import {
  defaultSortParams,
  options,
  optionsRu,
  queryParamsHandler,
} from '../utils';
import { FormRequest } from './FormRequest';
import { ProductCard } from './ProductCard';
import classes from './ProductList.module.scss';
import { ProductListProps } from './ProductList.props';
import ProductListFilters from './ProductListFilters/ProductListFilters';
import { SelectData } from 'components/shared/SelectComponent/SelectComponent.props';

export const ProductList = ({
  isLoading,
  data,
  defaultBudget,
  defaultPriceMin,
  setIsFree,
  setIsNew,
  setIsHot,
  setIsSale,
  setIsTopUp,
  hotDeals,
  nameSource,
  defaultCurrentPage,
  setCurrentPage,
  defaultServiceTypes,
  defaultBusinessTypes,
  setPageSize,
  topFilters,
  isFavorites,
  enableHints,
  sortSelect,
  setSortSelect,
  openSelect,
  setOpenSelect,
  defaultChunkSize = 8,
  setMoreButtonClicked,
  moreButtonClicked,
  setPriceMin,
  clearBusinessTypes,
  isFirstRender,
}: ProductListProps) => {
  const { t } = useTranslation();
  const count = marketPlaceStore?.count;
  const isMobile = useWindowWidth().isMediaTablet;

  const isLoadingServices = marketPlaceStore.isLoadingServicePages;

  const [nextPage, setNextPage] = useState('');
  const [prevPage, setPrevPage] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const [favouriteServices, setFavoriteServices] = useState(
    marketPlaceStore?.isFavoriteServices
  );
  const [products, setProducts] = useState(data);
  const [chunks, setChunks] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);
  const [showMore, setShowMore] = useState(0);

  const {
    defaultIsFree,
    defaultIsNew,
    defaultIsHot,
    defaultIsSale,
    defaultIsTopUp,
  } = topFilters;
  const { is_free, is_new, is_hot, is_sale, is_topup } =
    marketPlaceStore.isTopServices || {};
  const {
    is_free_favorite,
    is_hot_favorite,
    is_new_favorite,
    is_sale_favorite,
    is_topup_favorite,
  } = favouriteServices || {};
  const { sortingParams } = marketPlaceStore || {};
  const isFavoriteChanged = isFavorites && marketPlaceStore?.favoriteCount;

  const {
    savedCurrentPage,
    savedChunkSize,
    savedServiceTypes,
    savedBusinessTypes,
    isFavoritesPage,
    savedBudget,
    savedPriceMin,
    savedSortParams,
    isFree,
    isNew,
    isHot,
    isSale,
    isTopUp,
  } = (!isFavorites && sortingParams) || {};

  const currentPage = (!isFavorites && savedCurrentPage) || defaultCurrentPage;
  const chunkSize = (!isFavorites && savedChunkSize) || defaultChunkSize;
  const serviceTypes = savedServiceTypes || defaultServiceTypes;
  const businessTypes = savedBusinessTypes || defaultBusinessTypes;
  const budget = savedBudget || defaultBudget;
  const price_min = savedPriceMin || defaultPriceMin;
  const sortParams = savedSortParams || defaultSortParams(sortSelect);
  const freeFilter =
    isFavoritesPage || isFavorites ? is_free_favorite : is_free;
  const newFilter = isFavoritesPage || isFavorites ? is_new_favorite : is_new;
  const hotFilter = isFavoritesPage || isFavorites ? is_hot_favorite : is_hot;
  const saleFilter =
    isFavoritesPage || isFavorites ? is_sale_favorite : is_sale;
  const topUpFilter =
    isFavoritesPage || isFavorites ? is_topup_favorite : is_topup;

  useEffect(() => {
    if (typeof window !== 'undefined' && !moreButtonClicked) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [currentPage]);

  useEffect(() => {
    setFavoriteServices(marketPlaceStore?.isFavoriteServices);
  }, []);

  const handleClickMore = () => {
    setMoreButtonClicked(true);
    setDisableButton(isLoadingServicePages);
    if (nextPage) {
      const params = queryParamsHandler({
        page: currentPage + 1,
        page_size: chunkSize,
        serviceTypes: serviceTypes?.length && serviceTypes,
        businessTypes: businessTypes?.length && businessTypes,
        isFavorites: isFavoritesPage || isFavorites,
        budget: budget && budget,
        price_min: price_min && price_min,
        order: sortParams,
        isFree: isFree || defaultIsFree,
        isNew: isNew || defaultIsNew,
        isHot: isHot || defaultIsHot,
        isSale: isSale || defaultIsSale,
        isTopUp: isTopUp || defaultIsTopUp,
      });

      marketPlaceStore.loadServices(params, products).then((services) => {
        setProducts([...products, ...services]);
        setNextPage(marketPlaceStore?.nextPage);
        setPrevPage(marketPlaceStore?.prevPage);
        setCurrentPage(currentPage + 1);
      });
    }
  };

  useEffect(() => {
    const moreCount = currentPage * (chunkSize + 1) - 1;
    if (currentPage === 1) {
      setPageSize(chunkSize);
    } else {
      setPageSize(moreCount);
    }
  }, [currentPage]);

  function changeChunks() {
    let newArray: any = [];
    if (!isLoading) {
      for (let i = 0; i < count; i += chunkSize) {
        const chunk = products.slice(i, i + chunkSize);
        newArray = [...newArray, chunk];
      }
    }
    return newArray;
  }

  useEffect(() => {
    setChunks(changeChunks());
  }, [products, showMore, isLoading]);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    setProducts(data);
  }, [data]);

  const { isLoadingServicePages } = marketPlaceStore;
  const { currency, language, country } = userStore.user || {};

  const params = queryParamsHandler({
    page: currentPage,
    page_size: chunkSize,
    serviceTypes: serviceTypes?.length && serviceTypes,
    businessTypes: businessTypes?.length && businessTypes,
    isFavorites: isFavoritesPage || isFavorites,
    budget: budget && budget,
    price_min: price_min && price_min,
    order: sortParams,
    isFree: isFree || defaultIsFree,
    isNew: isNew || defaultIsNew,
    isHot: isHot || defaultIsHot,
    isSale: isSale || defaultIsSale,
    isTopUp: isTopUp || defaultIsTopUp,
    clearBusinessTypes: clearBusinessTypes ? clearBusinessTypes : false,
  });

  useEffect(() => {
    if (
      currentPage !== defaultCurrentPage ||
      price_min !== defaultPriceMin ||
      defaultSortParams(sortSelect) !== sortParams
    )
      return;

    !moreButtonClicked &&
      !userStore.localeChanged &&
      marketPlaceStore.loadServices(params).then((services: any) => {
        setProducts(services);
        setNextPage(marketPlaceStore?.nextPage);
        setPrevPage(marketPlaceStore?.prevPage);
      });
  }, [
    params,
    currentPage,
    currency?.name,
    language?.id,
    isFavoriteChanged,
    sortSelect?.id,
  ]);

  useEffect(() => {
    if (userStore.localeChanged) {
      marketPlaceStore.loadServices(params).then((services) => {
        setProducts(services);
        setNextPage(marketPlaceStore?.nextPage);
        setPrevPage(marketPlaceStore?.prevPage);
      });
      setCurrentPage(1);
    }
    userStore.setLocaleChanged(false);
  }, [currency?.name, language?.id, country?.id]);

  const handleNextPrevPage = (isNext: boolean) => {
    setMoreButtonClicked(false);
    if (isNext && nextPage) {
      setCurrentPage(currentPage + 1);
    } else if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const checkFilter = (filter?: boolean, defaultFilter?: boolean) => {
    if (isFavorites) {
      return defaultFilter;
    } else return filter || defaultFilter;
  };

  if ((isLoading && data.length === 0) || isLoadingServices || isFirstRender)
    return <ProductListSkeleton />;

  return (
    <div className={classes.wrapper}>
      <div className={classes.sorting}>
        <ProductListFilters
          showMore={showMore}
          setCurrentPage={setCurrentPage}
          checkFilter={checkFilter}
          freeFilter={freeFilter}
          isLoadingServices={isLoadingServices}
          setPriceMin={setPriceMin}
          setShowMore={setShowMore}
          setIsFree={setIsFree}
          isFree={isFree}
          defaultIsFree={defaultIsFree}
          setMoreButtonClicked={setMoreButtonClicked}
          newFilter={newFilter}
          setIsNew={setIsNew}
          isNew={isNew}
          defaultIsNew={defaultIsNew}
          hotFilter={hotFilter}
          setIsHot={setIsHot}
          defaultIsHot={defaultIsHot}
          isHot={isHot}
          defaultIsSale={defaultIsSale}
          isSale={isSale}
          setIsSale={setIsSale}
          saleFilter={saleFilter}
          defaultIsTopUp={defaultIsTopUp}
          isTopUp={isTopUp}
          setIsTopUp={setIsTopUp}
          topUpFilter={topUpFilter}
        />

        {!isMobile && (
          <Select
            setSelect={setSortSelect}
            select={sortSelect}
            options={RUSSIAN_LOCALE ? optionsRu(t) : options(t)}
            title={RUSSIAN_LOCALE ? undefined : t('Sort by')}
            open={openSelect}
            setOpen={setOpenSelect}
            boxClassName={classes.selectBox}
            wrapperClassName={classes.selectWrapper}
            dropdownClassName={classes.dropdown}
            onSelect={() => {
              setMoreButtonClicked(false);
              !showMore && setCurrentPage(1);
            }}
          />
        )}

        {openSelect && isMobile && (
          <ModalMobile
            title=""
            isOpen={openSelect}
            onClose={() => {
              setOpenSelect && setOpenSelect(false);
            }}
            isBasicHeader={false}
          >
            <div className={clsx(classes.fixedButtonSelect)}>
              {(RUSSIAN_LOCALE ? optionsRu(t) : options(t))?.map(
                (item: SelectData, index: number) => (
                  <Button
                    key={`${item.id}${index}`}
                    className={clsx(
                      classes.buttonSelect,
                      item?.id === sortSelect?.id && classes.activeSelect
                    )}
                    onClick={() => {
                      setOpenSelect && setOpenSelect(!openSelect);
                      if (item?.id !== sortSelect?.id) {
                        setMoreButtonClicked(false);
                        !showMore && setCurrentPage(1);

                        if (setSortSelect) setSortSelect(item);
                      }
                      setOpenSelect && setOpenSelect(false);
                    }}
                  >
                    {item?.name}
                    {item?.id === sortSelect?.id && (
                      <UiIcon
                        name="CheckIcon"
                        additionalClassName={classes.checkIconSelect}
                      />
                    )}
                  </Button>
                )
              )}
            </div>
          </ModalMobile>
        )}
      </div>

      <div className={classes.list}>
        {
          <>
            {products?.map(
              (item: any, index: number) =>
                item.is_active && (
                  <ProductCard
                    key={`${item.id}-${index}`}
                    data={item}
                    hotDeals={hotDeals}
                    nameSource={nameSource}
                  />
                )
            )}

            <div className={classes['request-card']}>
              <div className={classes.top}>
                <img
                  src={RUSSIAN_LOCALE ? request_ru : request}
                  alt=""
                  className={classes.image}
                />
                <div className={classes.title}>
                  {t('Looking for something else')}?
                </div>
                <div className={classes.text}>
                  {t(
                    'Tell us what solution you are looking for and we will send a proposal for you'
                  )}
                </div>
              </div>

              <div className={classes.bottom}>
                <Button
                  theme="default"
                  className={classes.button}
                  onClick={handleShowModal}
                >
                  {t('Send request_productList')}
                </Button>
              </div>

              <Modal
                title={''}
                isOpen={showModal}
                onClose={handleCloseModal}
                isBasicHeader={false}
              >
                <div className={classes.form}>
                  <FormRequest onClose={handleCloseModal} />
                </div>
              </Modal>
            </div>
          </>
        }
      </div>

      {enableHints && (
        <div className={classes.pagination}>
          {chunks.length !== currentPage && nextPage && (
            <Button
              onClick={handleClickMore}
              theme="light"
              className={classes.button}
              disabled={disableButton}
            >
              {t('See more')}
            </Button>
          )}

          {(nextPage || prevPage) && (
            <Pagination
              currentPage={currentPage + showMore / chunkSize}
              totalPages={chunks.length}
              setPageNumber={setCurrentPage}
              nextPage={() => handleNextPrevPage(true)}
              prevPage={() => handleNextPrevPage(false)}
              extraAction={() => {
                setShowMore(0);
                setMoreButtonClicked(false);
              }}
            />
          )}
        </div>
      )}

      {!enableHints &&
        products?.length > 0 &&
        (isLoadingServicePages ? (
          <div className={classes.loaderWrapper}>
            <Lottie animationData={loader} />
          </div>
        ) : (
          <div className={classes.pagination}>
            {chunks.length !== currentPage && nextPage && (
              <Button
                onClick={handleClickMore}
                theme="light"
                className={classes.button}
                size="small"
                disabled={disableButton}
              >
                {t('See more')}
              </Button>
            )}

            {(nextPage || prevPage) && (
              <Pagination
                currentPage={currentPage + showMore / chunkSize}
                totalPages={chunks.length}
                setPageNumber={setCurrentPage}
                nextPage={() => handleNextPrevPage(true)}
                prevPage={() => handleNextPrevPage(false)}
                extraAction={() => {
                  setShowMore(0);
                  setMoreButtonClicked(false);
                }}
              />
            )}
          </div>
        ))}
    </div>
  );
};
