import clsx from 'clsx';
import { Button } from 'components/shared/Button';
import { Grid, GridItem } from 'components/common/Grid/index';
import { CheckCircleGradientItem } from 'components/shared/Icons/CheckCircleGradientItem';
import { Textarea } from 'components/shared/Input/Textarea';
import { Type } from 'components/shared/Type/index';
import { defaultLanguage } from 'i18n/i18n';
import { Controller } from 'react-hook-form';
import { RUSSIAN_LOCALE } from 'utils';
import classes from './FormRequest.module.scss';
import { useFormRequest } from './useFormRequest';

export const FormRequest = ({ onClose }: { onClose: () => void }) => {
  const {
    success,
    handleSubmit,
    submit,
    t,
    control,
    errorMessage,
    setServiceValue,
    setSourceValue,
    i18n,
    isValid,
    onCloseSuccess,
  } = useFormRequest(onClose);

  return (
    <form
      action=""
      className={clsx(classes.form, success && classes.gradient)}
      onSubmit={handleSubmit(submit)}
    >
      {!success && (
        <div className={classes.inner}>
          <div className={classes.title}>
            <Type tag="span" size="xs-bold">
              {t('Request for a new solution')}
            </Type>
          </div>

          <Grid direction="row" className={classes.box}>
            <GridItem col={{ default: 12 }} className={classes.item}>
              <Controller
                control={control}
                render={({ field }) => (
                  <Textarea
                    error={errorMessage?.service_name}
                    value={field.value}
                    label={t('Solution')}
                    maxLength={100}
                    placeholder={t('Tell us more about your needs')}
                    onChange={(e: any) => {
                      setServiceValue(e);
                      field.onChange(e);
                    }}
                  />
                )}
                name="solution"
              />

              {errorMessage?.service_name ? (
                <p className={classes.error}>{errorMessage.service_name}</p>
              ) : null}
            </GridItem>

            <GridItem col={{ default: 12 }} className={classes.item}>
              <Controller
                control={control}
                render={({ field }) => (
                  <Textarea
                    maxLength={100}
                    error={errorMessage?.project_description}
                    value={field.value}
                    label={t('Source')}
                    placeholder={t('What source do you need this solution for')}
                    onChange={(e: any) => {
                      setSourceValue(e);
                      field.onChange(e);
                    }}
                  />
                )}
                name="source"
              />

              {errorMessage?.project_description ? (
                <p className={classes.error}>
                  {errorMessage.project_description}
                </p>
              ) : null}
            </GridItem>

            <GridItem col={{ default: 12 }} className={classes.item}>
              <p className={classes.policy}>
                {t(
                  'By applying this form You further agree on the terms of our'
                )}{' '}
                <a
                  className={classes['policy__link']}
                  href={
                    RUSSIAN_LOCALE
                      ? process.env.REACT_APP_PRIVACY_POLICY_URL
                      : `https://kit.global/${
                          i18n.language !== defaultLanguage
                            ? `${i18n.language}/`
                            : ''
                        }privacy-policy/`
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('Privacy Policy')}
                </a>
              </p>
            </GridItem>
          </Grid>

          <Button
            theme={'primary'}
            size="middle"
            disabled={!isValid}
            className={classes.btn}
            type="submit"
          >
            {t('Send request_productList')}
          </Button>
        </div>
      )}
      {success && (
        <div className={classes.inner}>
          <div className={classes.icon}>
            <CheckCircleGradientItem />
          </div>

          <div className={classes.title}>
            <span className={classes.successTitle}>{t('Request sent')}</span>
          </div>

          <div className={classes.text}>
            <Type size="simple" tag="p" className={classes.textItem}>
              {t(
                'Thank you, we’ll go back to you with more information within 2 working days'
              )}
            </Type>
          </div>

          <div className={classes.btnSuccess}>
            <Button size="middle" theme="primary" onClick={onCloseSuccess}>
              {t('Accessibly')}
            </Button>
          </div>
        </div>
      )}
    </form>
  );
};
