import { makeAutoObservable, runInAction } from 'mobx';
import Service from 'services/service';
import _ from 'lodash';
import { queryParamsHandler } from '../containers/CardsContainer/utils';

class MarketPlaceStore {
  serviceTypes = [];
  businessTypes = [];
  services = [];
  count = 0;
  allCount = 0;
  maxCost = 0;
  isLoadingServices = false;
  isLoadingServicePages = false;
  favoriteCount = 0;
  activeHotDeals = [];
  nextPage = '';
  prevPage = '';
  singleService = {};
  dataTimeHotDeals = {
    dayDeals: 0,
    hourDeals: 0,
    minutesDeals: 0,
    featureDeals: false,
    activeDeals: false,
    startDate: new Date(),
    endDate: new Date(),
  };
  isTopServices = {
    is_free: false,
    is_new: false,
    is_hot: false,
    is_sale: false,
    is_topup: false,
  };
  isFavoriteServices = {
    is_free_favorite: false,
    is_new_favorite: false,
    is_hot_favorite: false,
    is_sale_favorite: false,
    is_topup_favorite: false,
  };
  sortingParams = {
    savedCurrentPage: 1,
    savedChunkSize: 8,
    savedServiceTypes: [],
    savedBusinessTypes: [],
    isFavoritesPage: false,
    savedBudget: 0,
    savedPriceMin: 0,
    savedSortParams: '',
    isFree: false,
    isNew: false,
    isHot: false,
    isSale: false,
    isTopUp: false,
    sortSelect: {
      id: '',
      name: '',
    },
    clearBusinessTypes: false,
  };
  isErrorServices = false;

  constructor() {
    makeAutoObservable(this);
  }

  async loadServiceTypes() {
    const serviceTypes = await Service.getServiceTypes();
    this.setServiceTypes(serviceTypes?.data?.results);
    return this.serviceTypes;
  }

  setServiceTypes(serviceTypes: any) {
    this.serviceTypes = serviceTypes || [];
  }

  async loadBusinessTypes() {
    const businessTypes = await Service.getBusinessTypes();
    this.setBusinessTypes(businessTypes?.data?.results);
    return this.businessTypes;
  }

  setBusinessTypes(businessTypes: any) {
    this.businessTypes = businessTypes || [];
  }

  async loadTypes() {
    const businessTypesPromise = this.loadBusinessTypes();
    const serviceTypesPromise = this.loadServiceTypes();
    return await Promise.all([businessTypesPromise, serviceTypesPromise]);
  }

  setServices(services: any) {
    this.services = services || [];
  }

  async loadHotDeals(params?: string) {
    const res = await Service.getServices(params);
    await this.loadActiveHotDeals(res.data?.results);
    await this.loadDataTimeHotDeals();
    return res;
  }

  async loadServices(params?: string, moreServices?: any) {
    if (!params) {
      runInAction(() => {
        this.isLoadingServices = true;
      });
    } else {
      runInAction(() => {
        this.isLoadingServicePages = true;
      });
    }

    const resetParams = queryParamsHandler({ page: 1, page_size: 8 });
    const res = await Service.getServices(params || resetParams);
    const { is_free, is_new, is_hot, is_sale, is_topup } = res?.data || {};
    const {
      is_free_favorite,
      is_new_favorite,
      is_hot_favorite,
      is_sale_favorite,
      is_topup_favorite,
    } = res?.data || {};

    this.isTopServices = { is_free, is_new, is_hot, is_sale, is_topup };
    this.isFavoriteServices = {
      is_free_favorite,
      is_new_favorite,
      is_hot_favorite,
      is_sale_favorite,
      is_topup_favorite,
    };

    this.setServices(res.data?.results);
    this.nextPage = res.data?.next;
    this.prevPage = res.data?.previous;

    this.setFavoriteCount(this.favoriteServices.length);

    this.allCount = res.data?.count;

    this.favoriteCount = res.data?.favorites;

    this.count = res.data?.count;

    this.maxCost = res.data?.maxCost;

    runInAction(() => {
      this.isLoadingServices = false;
      this.isLoadingServicePages = false;
    });

    return this.services;
  }

  getServiceData = (slug: string) =>
    this.services.find((s: any) => s.slug === slug);

  async loadActiveHotDeals(services: any = []) {
    if (Array.isArray(services) && services.length > 0) {
      const activeHotDeals = Object.values(
        services
          .filter((item: any) => item.discounts.length)
          .filter((item: any) =>
            item.discounts.some(
              (item: any) => item.type === 'HOT_DEAL' && item.is_active
            )
          )
      );
      this.setActiveHotDeals(activeHotDeals);
      return activeHotDeals;
    }
  }

  setActiveHotDeals(activeHotDeals: any) {
    this.activeHotDeals = activeHotDeals || [];
  }

  async loadDataTimeHotDeals() {
    let dataTimeHotDeals = {
      dayDeals: 0,
      hourDeals: 0,
      minutesDeals: 0,
      featureDeals: false,
      activeDeals: false,
      startDate: new Date(),
      endDate: new Date(),
    };
    const arrStartDate = this.activeHotDeals
      .map((item: any) =>
        item.discounts.filter((item: any) => item.type === 'HOT_DEAL')
      )
      .flat()
      .map((item: any) => item.start_date);

    const arrEndDate = this.activeHotDeals
      .map((item: any) =>
        item.discounts.filter((item: any) => item.type === 'HOT_DEAL')
      )
      .flat()
      .map((item: any) => item.end_date);

    const minStart: Date = _.min(arrStartDate);
    const maxEnd: Date = _.max(arrEndDate);

    const formatted: number = new Date(minStart).getTime();

    const today: any = new Date();

    const subtractDays = Math.floor(
      (formatted - today) / (1000 * 60 * 60 * 24)
    );

    if (subtractDays >= 0 && subtractDays < 7) {
      dataTimeHotDeals = {
        dayDeals: subtractDays,
        hourDeals: Math.floor(((formatted - today) / (1000 * 60 * 60)) % 24),
        minutesDeals: Math.floor(((formatted - today) / (1000 * 60)) % 60),
        featureDeals: true,
        activeDeals: true,
        startDate: minStart,
        endDate: maxEnd,
      };
    } else if (subtractDays < 0) {
      const dateEnd = maxEnd;
      const formattedDateEnd: any = new Date(dateEnd);

      dataTimeHotDeals = {
        dayDeals: Math.floor(
          (formattedDateEnd - today) / (1000 * 60 * 60 * 24)
        ),
        hourDeals: Math.floor(
          ((formattedDateEnd - today) / (1000 * 60 * 60)) % 24
        ),
        minutesDeals: Math.floor(
          ((formattedDateEnd - today) / (1000 * 60)) % 60
        ),
        featureDeals: false,
        activeDeals: true,
        startDate: minStart,
        endDate: maxEnd,
      };
    } else {
      dataTimeHotDeals.activeDeals = false;
    }
    this.setDataTimeHotDeals(dataTimeHotDeals);
    return dataTimeHotDeals;
  }

  setDataTimeHotDeals(dataTimeHotDeals: any) {
    this.dataTimeHotDeals = dataTimeHotDeals || {};
  }

  get favoriteServices() {
    return this.services.filter((service: any) => service.is_favorite);
  }

  setFavoriteCount(count: number) {
    this.favoriteCount = count;
  }

  async addServiceToFavorite(serviceId: number) {
    await Service.addServiceToFavorite(serviceId);
    this.setFavoriteCount(this.favoriteCount + 1);
    const newServices = [...this.services];
    runInAction(() => {
      newServices.forEach((s: any) => {
        if (s.id === serviceId) {
          s.is_favorite = true;
        }
      });
      this.setServices(newServices);
    });
  }

  async removeServiceFromFavorite(serviceId: number) {
    await Service.removeServiceFromFavorite(serviceId);
    this.setFavoriteCount(this.favoriteCount - 1);
    const newServices = [...this.services];
    runInAction(() => {
      newServices.forEach((s: any) => {
        if (s.id === serviceId) {
          s.is_favorite = false;
        }
      });
      this.setServices(newServices);
    });
  }

  async loadSingleService(serviceId: string) {
    try {
      const response = await Service.getService(serviceId);
      this.singleService = response.data;
    } catch (error) {
      this.singleService = { notFound: true };
    }
  }

  async incrementServiceViews(serviceId: string) {
    try {
      await Service.IncrementViews(serviceId);
    } catch (error) {
      console.error(error);
    }
  }

  setIsErrorServices(isErrorServices: boolean) {
    this.isErrorServices = isErrorServices;
  }

  async IncrementViews(slug: string) {
    const response = await Service.IncrementViews(slug);
    if (response.status === 404) {
      this.setIsErrorServices(true);
    } else this.setIsErrorServices(false);
  }

  setSortingParams(params: any) {
    this.sortingParams = {
      ...params,
    };
  }

  clearStore() {
    this.sortingParams = {
      savedCurrentPage: 1,
      savedChunkSize: 8,
      savedServiceTypes: [],
      savedBusinessTypes: [],
      isFavoritesPage: false,
      savedBudget: 0,
      savedPriceMin: 0,
      savedSortParams: '',
      isFree: false,
      isNew: false,
      isHot: false,
      isSale: false,
      isTopUp: false,
      sortSelect: {
        id: '',
        name: '',
      },
      clearBusinessTypes: false,
    };
    this.isErrorServices = false;
    this.serviceTypes = [];
    this.businessTypes = [];
    this.services = [];
    this.isLoadingServices = false;
    this.favoriteCount = 0;
    this.activeHotDeals = [];
    this.singleService = {};
    this.dataTimeHotDeals = {
      dayDeals: 0,
      hourDeals: 0,
      minutesDeals: 0,
      featureDeals: false,
      activeDeals: false,
      startDate: new Date(),
      endDate: new Date(),
    };
  }
}

export const marketPlaceStore = new MarketPlaceStore();
