import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { userStore } from 'stores/userStore';
import { FilterProps } from 'types/solutions';

const filterLimits = 5;

export const useCategoryFilter = (
  setClearBusinessTypes: Dispatch<SetStateAction<boolean>>,
  setServiceTypes: (val: any) => void,
  setIsFirstRender: Dispatch<SetStateAction<boolean>>,
  setBusinessTypes: (val: any) => void,
  businessTypesChecks: string[],
  serviceTypesOf: any,
  businessTypesOf: any,
  handleFilter: (options: FilterProps) => void,
  isFavorites?: boolean
) => {
  const [serviceOptions, setServiceOptions] = useState(serviceTypesOf);
  const [serviceSearch, setServiceSearch] = useState('');
  const [businessOptions, setBusinessOptions] = useState(businessTypesOf);
  const [businessSearch, setBusinessSearch] = useState('');
  const [serviceLimit, setServiceLimit] = useState(filterLimits);
  const [businessLimit, setBusinessLimit] = useState(filterLimits);

  const user = userStore.user;

  useEffect(() => {
    if (serviceSearch !== '') {
      const newOptions = serviceTypesOf.filter((item: any) => {
        if (item.name.toLowerCase().includes(serviceSearch.toLowerCase())) {
          return item;
        }
      });
      setServiceOptions(newOptions);
      return;
    }
    setServiceOptions(serviceTypesOf);
  }, [serviceSearch, serviceTypesOf]);

  useEffect(() => {
    if (businessSearch !== '') {
      const newOptions = businessTypesOf.filter((item: any) => {
        if (item.name.toLowerCase().includes(businessSearch.toLowerCase())) {
          return item;
        }
      });

      setBusinessOptions(newOptions);
      return;
    }

    // If the user has a business type specified, we display it at the top of the filter list.
    if (user.company_industry && !isFavorites) {
      const indexUserCompanyIndustry = businessTypesOf.findIndex(
        (item: any) => item.id === user.company_industry
      );

      if (indexUserCompanyIndustry > -1) {
        const newOptions = businessTypesOf;
        const [businessType] = businessTypesOf.splice(
          indexUserCompanyIndustry,
          1
        );
        newOptions.unshift(businessType);

        if (!businessTypesChecks.find((item) => item === businessType.id)) {
          setBusinessTypes([...businessTypesChecks, businessType.id]);
        }

        setBusinessOptions(newOptions);
      }
      setIsFirstRender(false);
    } else {
      setBusinessOptions(businessTypesOf);
      setIsFirstRender(false);
    }
  }, [businessSearch, businessTypesOf]);

  const resetSearchService = () => {
    setServiceTypes([]);
    setServiceSearch('');
    handleFilter({ serviceTypes: [] });
  };

  const resetSearchBusiness = () => {
    setClearBusinessTypes(true);
    setBusinessTypes([]);
    setBusinessSearch('');
    handleFilter({ businessTypes: [] });
  };

  return {
    resetSearchService,
    filterLimits,
    serviceLimit,
    setServiceSearch,
    serviceSearch,
    serviceOptions,
    setServiceLimit,
    resetSearchBusiness,
    businessLimit,
    setBusinessSearch,
    businessSearch,
    businessOptions,
    setBusinessLimit,
  };
};
